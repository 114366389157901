// External Imports
import { useContext, useState } from "react";
import Papa from "papaparse";

// Internal Imports
import { DataContext } from "../../context/data-context";
import { requestAdminAPI } from "../../api/posh-api";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";

// Internal Components
import Heading from "../UI/Heading";

/** ========================================================================
 * * Add Charging Page
 * Renders a page, which allows the admin to upload EV charging records.
 * ========================================================================== */

export default function AddCharging() {
  // Load Context
  const { fetchCharging } = useContext(DataContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [ignored, setIgnored] = useState([]);

  /* ======================= SUBMIT CSV FILE ======================= */
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    // Read file and set data
    const file = e.target.file.files[0];
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      uploadChargingHandler(parsedData.reverse());
    };
    reader.readAsText(file);
    setLoading(false);
  };

  /* ======================= UPLOAD CHARGING ======================= */
  const uploadChargingHandler = async (data) => {
    try {
      setLoading(true);
      // Split Data in chunks of 100
      const chunks = [];
      for (let i = 0; i < data.length; i += 100) {
        chunks.push(data.slice(i, i + 100));
      }

      // Create array for ignored entries
      const ignored = [];

      // Upload each chunk
      await Promise.all(
        chunks.map(async (chunk) => {
          const res = await requestAdminAPI("upload-charging", {
            charging: chunk,
          });
          ignored.push(...res);
        })
      );
      setIgnored(ignored);
      fetchCharging(() => setLoading(false));
    } catch (err) {
      console.err(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="w-full h-full p-8 overflow-scroll rounded-xl bg-stone-900">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div className="container">
      <div className="grid gap-8">
        <div className="grid gap-2">
          <Heading title="Add EV Charging" />
          <hr className="hr" />
          <p className="text-sm text-stone-400">
            Download the csv file from the Tesla App (Profile &#62; Account
            &#62; Charging &#62; History) and upload the file here. Older
            records and duplicates are automatically ignored.
          </p>
        </div>

        <form onSubmit={submitHandler} className="grid gap-6">
          <div className="grid gap-4">
            <label className="form-label">Upload CSV:</label>
            <input type="file" name="file" className="form-input" required />
          </div>
          <button type="submit" className="btn-primary md:w-32">
            Submit
          </button>
        </form>

        {ignored.length > 0 && <p>Entries Ignored: {ignored.length}</p>}
        {warning && <p className="warning">{warning}</p>}
      </div>
    </div>
  );
}
