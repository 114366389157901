import {useContext } from "react";
import { DataContext } from "../../context/data-context";
import { useSearchParams } from "react-router-dom";
import PaymentForm from "../UI/PaymentForm";
import Heading from "../UI/Heading";

export default function AddPayment() {
  const { customers } = useContext(DataContext);
  const [searchParams] = useSearchParams();

  const customerId = searchParams.get("customerId");
  const bookingId = searchParams.get("bookingId");
  const amount = searchParams.get("amount");
  const description = searchParams.get("description");
  const selectedCustomer = customers.filter(
    (customer) => customer.id === customerId
  )[0];

  

  return (
    <div className="container">
      <Heading title="Add Payment" text="For ACH, customer's balance is automatically checked and transaction is halted if it is insufficient."/>
      <div className="my-6">
        <PaymentForm
          amount={amount}
          selectedCustomer={selectedCustomer}
          description={description}
          bookingId={bookingId}
          attachFile={true}
        />
      </div>
    </div>
  );
}
