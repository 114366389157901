// External Packages
import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Internal Components
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import { DataContext } from "../../context/data-context";
import { BsPlusLg } from "react-icons/bs";
import ExpenseCard from "./ExpenseCard";
import { timestampToDate } from "../../util/timestamp-to-date";
import { UtilityContext } from "../../context/util-context";
import Heading from "../UI/Heading";

export default function Expenses() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Load Context
  const { expenses, vehicles } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Default Filters
  const defaultFilter = {
    vehicle: params.get("vehicle") ?? "",
    from: params.get("from") ?? "",
    to: params.get("to") ?? "",
  };

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [filteredExpenses, setFilteredExpenses] = useState(expenses);
  const [filter, setFilter] = useState({ ...defaultFilter });

  useEffect(() => {
    const { vehicle, from, to } = filter;
    if (!vehicle && !from && !to) {
      setFilteredExpenses(expenses);
      return;
    }
    const vehicleId = vehicle;
    const startDate = new Date(from);
    const endDate = new Date(to);
    const filteredExpenses = expenses.filter((expense) => {
      return (
        expense.vehicle.id.includes(vehicleId) &&
        timestampToDate(expense.date) >= startDate &&
        timestampToDate(expense.date) <= endDate
      );
    });
    setFilteredExpenses(filteredExpenses);
  }, [expenses, params.get("vehicle"), params.get("from"), params.get("to")]);

  const filterExpensesHandler = (e) => {
    e.preventDefault();

    const { vehicle, from, to } = filter;
    navigate(
      `/expenses?vehicle=${vehicle}&from=${from}&to=${to}`,
      {},
      { replace: true }
    );

    setFilter({ ...filter, vehicle, from, to });
    setLoading(true);
    const vehicleId = vehicle;
    const startDate = new Date(from);
    const endDate = new Date(to);

    const filteredExpenses = expenses.filter((expense) => {
      return (
        expense.vehicle.id.includes(vehicleId) &&
        timestampToDate(expense.date) >= startDate &&
        timestampToDate(expense.date) <= endDate
      );
    });
    setFilteredExpenses(filteredExpenses);
    setLoading(false);
  };

  const getTotalAmount = (data) => {
    let total = 0;
    data.forEach((expense) => {
      total += Number(expense.amount);
    });
    return total.toFixed(2);
  };

  const handleChange = (e) =>
    setFilter({ ...filter, [e.target.name]: e.target.value });

  const searchBar = (
    <form
      onSubmit={filterExpensesHandler}
      className="grid items-end gap-4 p-4 h-fit md:grid-cols-5 bg-stone-800 rounded-xl"
    >
      <div className="grid gap-2 md:col-span-2">
        <label className="text-sm">Vehicle:</label>
        <select
          className="select"
          name="vehicle"
          defaultValue={filter.vehicle}
          onChange={handleChange}
        >
          <option value="">All</option>
          {vehicles.map((vehicle) => (
            <option value={vehicle.id} key={vehicle.id}>
              {vehicle.make +
                " " +
                vehicle.model +
                " " +
                vehicle.color +
                " " +
                vehicle.license}
            </option>
          ))}
        </select>
      </div>
      <div className="grid gap-2">
        <label className="text-sm">From:</label>
        <input
          type="date"
          className="form-input"
          name="from"
          required
          value={filter.from}
          onChange={handleChange}
        />
      </div>
      <div className="grid gap-2">
        <label className="text-sm">To:</label>
        <input
          type="date"
          className="form-input"
          name="to"
          required
          value={filter.to}
          onChange={handleChange}
        />
      </div>
      <button type="submit" className="btn-primary">
        Search
      </button>
    </form>
  );

  if (loading)
    return (
      <div className="container">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div className="container grid">
      <Heading title="Expenses" isBackArrow={false}>
        <BsPlusLg
          onClick={(e) => navigate("/expenses/add", e)}
          className="text-lg text-white cursor-pointer hover:text-stone-400"
          title="Add Payment"
        />
      </Heading>

      {searchBar}

      <div className="list">
        <div className="grid items-start gap-4">
          <div className="hidden gap-6 mx-4 text-sm md:grid md:grid-cols-6 text-stone-400">
            <p className="md:col-span-2">Vehicle</p>
            <p className="md:col-span-2">Description</p>
            <p>Date</p>
            <p>Amount</p>
          </div>
          {filteredExpenses &&
            filteredExpenses.map((expense) => (
              <ExpenseCard key={expense.id} expense={expense} />
            ))}
          {filteredExpenses && filteredExpenses.length === 0 && (
            <p className="text-center text-stone-400">No records found!</p>
          )}
        </div>
      </div>

      <div className="flex items-center justify-end space-x-2">
        <p className="text-stone-400">
          Total: ${getTotalAmount(filteredExpenses)}
        </p>
      </div>
    </div>
  );
}
