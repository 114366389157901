// External Packages
import { useState, useEffect, useContext, useCallback } from "react";

// External Components
import { MdRefresh } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";

// Internal Components
import { UtilityContext } from "../../context/util-context";
import { requestAdminAPI } from "../../api/posh-api";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import LinkPair from "../UI/LinkPair";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";

export default function VehiclePartner({ vehicle }) {
  // Load Context
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [partner, setPartner] = useState();
  const [partnerVehicle, setPartnerVehicle] = useState();
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(null);

  // =================== FETCH SAMSARA INFO ===================
  const fetchPartnerHandler = useCallback(async () => {
    if (!vehicle.partnerId) return;
    try {
      setWarning();
      setLoading(true);
      const data = await requestAdminAPI("fetch-partner", {
        partnerId: vehicle.partnerId,
      });
      setPartner(data);
      setPartnerVehicle(data.vehicles.find((v) => v.id === vehicle.id));
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  }, [vehicle]);

  // =================== USE EFFECT ===================
  useEffect(() => {
    if (vehicle) fetchPartnerHandler();
  }, [fetchPartnerHandler, vehicle]);

  return (
    <div className="grid gap-4 mb-4">
      <Heading
        title="Vehicle Partner"
        text="This information is collected from the partner's vehicle record. To get more information, click external link icon on the right of group heading."
        isBackArrow={false}
      >
        <MdRefresh onClick={fetchPartnerHandler} className="text-2xl icon" />
        <FiExternalLink
          onClick={(e) =>
            navigate(
              `/partners/vehicle?partnerId=${partner?.id}&vin=${partnerVehicle?.vin}`,
              e
            )
          }
          className="icon"
        />
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="info">
          <DetailPair label="Partner" value={partner?.name} />
          <LinkPair
            label="Registeration"
            link={partnerVehicle?.registeration}
          />
          <LinkPair label="Insurance" link={partnerVehicle?.insurance} />
        </div>
      )}
      {warning && <p className="text-sm text-red-600">{warning}</p>}
    </div>
  );
}
