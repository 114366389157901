import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase";

export function uploadImageToFirebase(path, title, image, setUploadingImage) {
  if (image && path && title && setUploadingImage) {
    return new Promise(function (resolve, reject) {
      const storageRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(storageRef, image);
      setUploadingImage([title, 0]);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.ceil(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadingImage([title, progress]);
        },
        (error) => {
          console.log(error);
          reject("");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            resolve(url);
          });
        }
      );
    });
  } else {
    return "";
  }
}
