import { FaCar } from "react-icons/fa";
import { format } from "date-fns";

export default function PartnerVehicleCard({ vehicle, onClick }) {
  const classNames = (a) => {
    const classes =
      "grid items-center text-sm md:grid-cols-7 pill justify-items-center text-stone-400";
    if (a) return classes + " border-green-700";
    else return classes;
  };
  return (
    <div
      onClick={onClick && onClick}
      className={classNames(vehicle.status === "active")}
    >
      {vehicle.image ? (
        <img className="h-16 md:h-10" src={vehicle.image} />
      ) : (
        <FaCar className="icon text-stone-400" />
      )}
      <p className="md:col-span-2">
        {vehicle.make} {vehicle.model} {vehicle.year}
      </p>
      <p className="md:col-span-2">{vehicle.vin}</p>
      <p className="">{vehicle.monthly ? "$" + vehicle.monthly : "-"}</p>
      <p className="">{`${
        vehicle.due ? format(new Date(vehicle.due), "do MMM") : "-"
      }`}</p>
    </div>
  );
}
