export default function NotFound() {
  return (
    <div className="h-screen w-full justify-center">
      <p className="text-4xl text-center mt-20">🚧</p>
      <p className="text-center font-semibold text-black mt-2">
        This page is underconstruction
      </p>
    </div>
  );
}
