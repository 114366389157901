import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/auth-context";
import { DataContext } from "../../context/data-context";
import { useSearchParams } from "react-router-dom";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import { fetchTollsByBookingId } from "../../api/fetch";
import TollCard from "./TollCard";
import PaymentForm from "../UI/PaymentForm";
import { timestampToString } from "../../util/timestamp-to-date";
import { MdRefresh } from "react-icons/md";
import Heading from "../UI/Heading";

export default function BookingTolls() {
  const { user } = useContext(AuthContext);
  const { customers, bookings } = useContext(DataContext);

  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [tolls, setTolls] = useState([]);
  const [total, setTotal] = useState(0);

  // Get Booking and Customer from url
  const [searchParams] = useSearchParams();
  const bookingId = searchParams.get("bookingId");
  const booking = bookings.filter((booking) => booking.id === bookingId)[0];
  const customerId = searchParams.get("customerId");
  const customer = customers.filter(
    (customer) => customer.id === customerId
  )[0];

  const fetchBookingTolls = async () => {
    try {
      setLoading(true);

      // Send API Request
      const response = await fetchTollsByBookingId(user, {
        bookingId: bookingId,
      });
      selectAllHandler(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  const calculateTotal = (tolls) => {
    const selectedTolls = tolls.filter((toll) => toll.selected);
    let newTotal = 0;
    selectedTolls.forEach((toll) => {
      newTotal += Number(toll.amount);
    });
    setTotal(newTotal.toFixed(2));
  };

  const selectHandler = (toll) => {
    toll.selected = !toll.selected;
    setTolls([...tolls]);
    calculateTotal(tolls);
  };

  const selectAllHandler = (tolls) => {
    tolls.forEach((toll) => {
      toll.selected = true;
    });
    setTolls([...tolls]);
    calculateTotal(tolls);
  };

  const unselectAllHandler = () => {
    tolls.forEach((toll) => {
      toll.selected = false;
    });
    setTolls([...tolls]);
    calculateTotal(tolls);
  };

  useEffect(() => {
    fetchBookingTolls();
  }, []);

  if (!bookingId)
    return (
      <div className="container">
        <p className="text-white">Booking ID not provided!</p>
      </div>
    );

  if (warning)
    return (
      <div className="container">
        <p className="text-red-800">{warning}</p>
      </div>
    );

  if (loading)
    return (
      <div className="container">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div className="container">
      <div className="grid gap-4">
        <Heading
          title="Booking Tolls"
          text="Tolls already include a 15% processing fee. When billing customers, 
          make sure to unselect tolls which have already been invoiced/paid."
        >
          <MdRefresh onClick={fetchBookingTolls} className="icon" />
        </Heading>
        <div className="flex justify-between text-sm cursor-pointer hover:text-stone-400">
          <p>
            {booking.customer.firstName} {booking.customer.lastName}{" - "}
            {booking.vehicle?.make} {booking.vehicle?.model}{" "}
            {booking.vehicle.year}
          </p>
          <div className="flex items-center space-x-6">
            <p onClick={() => selectAllHandler(tolls)}>Select All</p>
            <p onClick={unselectAllHandler}>Unselect All</p>
          </div>
        </div>
        <div className="max-h-[422px] overflow-scroll">
          <div className="grid gap-4">
            {tolls.map((toll) => (
              <TollCard
                key={toll.id}
                toll={toll}
                selected={toll.selected}
                onClick={() => selectHandler(toll)}
              />
            ))}
            {tolls?.length === 0 && (
              <p className="text-center text-stone-400">No tolls found!</p>
            )}
          </div>
        </div>
        <div className="flex space-x-4 text-lg">
          <p className="font-semibold">Total: </p>
          <p className="font-semibold">${total}</p>
          <p className="text-red-800">{warning}</p>
        </div>
        {tolls?.length > 0 && (
          <PaymentForm
            amount={total}
            selectedCustomer={customer}
            description={`Tolls for ${booking?.vehicle?.make} ${
              booking?.vehicle?.model
            } (${timestampToString(
              booking.start_date,
              "MM/dd/yyyy"
            )} - ${timestampToString(booking.end_date, "MM/dd/yyyy")})`}
            bookingId={bookingId}
          />
        )}
      </div>
    </div>
  );
}
