// External Imports
import { useState, useEffect, useContext, useCallback, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { FaImages, FaPlay, FaStop } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";

// Internal Imports
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { requestAdminAPI } from "../../api/posh-api";
import { timestampToString } from "../../util/timestamp-to-date";
import { toTitleCase } from "../../util/format-text";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import DetailPair from "../UI/DetailPair";
import Heading from "../UI/Heading";
import ExtendBooking from "./ExtendBooking";
import CheckInForm from "./CheckInForm";
import CheckOutForm from "./CheckOutForm";
import DropDownBanner from "../UI/DropDownBanner";
import Notes from "../UI/Notes";
import BookingImages from "./BookingImages";

/** ========================================================================
 * * Booking Information Page
 * Renders a page, which displays all information about a booking.
 * ========================================================================== */

export default function BookingInfo() {
  // Load Context
  const { refreshData, vehicles } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Get Booking ID from URL
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  // Initialize States
  const [booking, setBooking] = useState();
  const [loading, setLoading] = useState(true);
  const [vehicleReport, setVehicleReport] = useState();
  const [warning, setWarning] = useState(false);
  const [extend, setExtend] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [checkOut, setCheckOut] = useState(false);
  const [images, setImages] = useState(false);

  /* ======================= FETCH BOOKING DATA ======================= */
  const fetchBookingData = useCallback(async () => {
    try {
      setLoading(true);
      setCheckIn(false);
      setCheckOut(false);
      setExtend(false);
      const response = await requestAdminAPI("fetch-booking", {
        bookingId: id,
      });
      setBooking(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  }, [id]);

  /* ======================= CANCEL BOOKING ======================= */
  const cancelBookingHandler = async () => {
    try {
      setLoading(true);
      await requestAdminAPI("cancel-booking", { bookingId: id });
      refreshData(() => navigate(`/bookings`));
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  /* ======================= FORCE AGREEMENT ======================= 
  This function request the API to generate a rental agreement even if
  the customer has not signed it yet. */
  const forceAgreementHandler = async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("force-agreement", {
        bookingId: id,
      });
      window.open(response.url, "_blank");
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      setLoading(false);
    }
  };

  /* ======================= GENERATE DEMAND LETTER ======================= */
  const generateDemandLetterHandler = async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("get-demand-letter", {
        bookingId: id,
      });
      window.open(response.url, "_blank");
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      setLoading(false);
    }
  };

  /* ======================= FETCH VEHICLE REPORT ======================= */
  const getVehicleReport = async () => {
    try {
      const vehicle = vehicles.filter((v) => v.id === booking.vehicle.id)[0];
      const response = await requestAdminAPI("fetch-vehicle-reports", {
        vehicles: [{ id: vehicle.id, vin: vehicle.vin }],
      });
      const extraMiles =
        response[0].mileage -
        Number(booking.start_mileage) -
        Number(booking.miles_included);
      const result = [
        {
          "Current Mileage": response[0].mileage,
          "Miles Driven": response[0].mileage - booking.start_mileage,
          "Extra Mileage": extraMiles > 0 ? extraMiles : 0,
          Charge:
            extraMiles > 0
              ? `$${(extraMiles * booking.mileage_rate).toFixed(2)}`
              : "-",
        },
      ];
      setVehicleReport(result);
    } catch (err) {
      setWarning(err.message);
    }
  };

  // Fetch booking data on load
  useEffect(() => {
    fetchBookingData();
  }, [fetchBookingData]);

  /* ======================= RENDER ICONS ======================= 
   Render Icons based on Booking Status */
  const renderIcons = () => {
    if (booking?.status === "confirmed" || booking?.status === "pending")
      return (
        <Fragment>
          <TiCancel
            onClick={cancelBookingHandler}
            className="text-3xl text-white cursor-pointer hover:text-stone-400"
            title="Cancel Booking"
          />
          <FaPlay
            onClick={() => setCheckIn(true)}
            className="text-lg text-white cursor-pointer hover:text-stone-400"
            title="Start Booking"
          />
        </Fragment>
      );
    if (booking?.status === "active")
      return (
        <FaStop
          onClick={() => setCheckOut(true)}
          className="text-lg text-white cursor-pointer hover:text-stone-400"
          title="Complete Booking"
        />
      );
  };

  if (!id)
    return (
      <div className="container">
        <p className="text-stone-400">Booking ID Not Provided!</p>
      </div>
    );

  if (!loading && !booking)
    return (
      <div className="container">
        <p className="text-stone-400">Booking Not Found!</p>
      </div>
    );

  if (checkIn)
    return (
      <CheckInForm
        setOpen={setCheckIn}
        booking={booking}
        onComplete={fetchBookingData}
      />
    );
  if (checkOut)
    return (
      <CheckOutForm
        setOpen={setCheckOut}
        booking={booking}
        onComplete={fetchBookingData}
      />
    );
  if (images) return <BookingImages booking={booking} setOpen={images} />;

  return (
    <div className="container">
      <Heading title="Booking Details">
        {renderIcons()}
        <FaImages
          onClick={() => setImages(true)}
          className="text-lg text-white cursor-pointer hover:text-stone-400"
          title="View Images"
        />
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="info">
          <DetailPair label="Booking ID" value={booking.id} />
          <DetailPair
            label="Customer ID"
            value={booking.customer.id}
            link={`/customers/info?id=${booking.customer.id}`}
          />
          <DetailPair
            label="Vehicle ID"
            value={booking.vehicle.id}
            link={`/vehicles/info?id=${booking.vehicle.id}`}
          />
          <DetailPair
            label="Vehicle"
            value={
              booking.vehicle.make +
              " " +
              booking.vehicle.model +
              " " +
              booking.vehicle.year +
              " " +
              booking.vehicle.license
            }
            link={`/vehicles/info?id=${booking.vehicle.id}`}
          />
          <DetailPair
            label="Customer"
            value={booking.customer.firstName + " " + booking.customer.lastName}
            link={`/customers/info?id=${booking.customer.id}`}
          />
          <DetailPair label="Status" value={toTitleCase(booking.status)} />
          <DetailPair
            label="Date Created"
            value={timestampToString(
              booking.date_created,
              "eee dd MMM, yyyy - hh:mm a"
            )}
          />
          <DetailPair
            label="Start Date"
            value={timestampToString(
              booking.start_date,
              "eee dd MMM, yyyy - hh:mm a"
            )}
          />
          <DetailPair
            label="End Date"
            value={timestampToString(
              booking.end_date,
              "eee dd MMM, yyyy - hh:mm a"
            )}
          />
          <DetailPair
            label="Pick Up Location"
            value={booking.pick_up_location}
          />
          <DetailPair
            label="Drop Off Location"
            value={booking.drop_off_location}
          />
          <DetailPair
            label="Extra Mileage Rate"
            value={`$${booking.mileage_rate || "0.35"}/mi`}
          />
          <DropDownBanner
            data={vehicleReport || [{ Loading: "Fetching Mileage" }]}
            onClick={getVehicleReport}
          >
            <DetailPair
              label="Start Mileage"
              value={`${booking.start_mileage || "-"}mi`}
            />
          </DropDownBanner>
          <DetailPair
            label="Miles Included"
            value={`${booking.miles_included || "-"}mi`}
          />
          {booking?.status === "completed" && (
            <DetailPair
              label="End Mileage"
              value={`${booking.end_mileage || "-"}mi`}
            />
          )}
          <DetailPair
            label="Start Fuel"
            value={`${booking.start_fuel || "-"}%`}
          />
          {booking?.status === "completed" && (
            <DetailPair
              label="End Fuel"
              value={`${booking.end_fuel || "-"}%`}
            />
          )}
          <DetailPair
            label="Delivery Fee"
            value={`$${booking?.price?.delivery || "-"}`}
          />
          <DetailPair
            label="Booking Total"
            value={`$${booking?.price?.booking || "-"}`}
          />
          <DetailPair
            label="Total (incl. taxes)"
            value={"$" + booking?.price?.total || "-"}
          />

          <hr className="hr md:col-span-3" />
          <div className="text-sm text-start md:col-span-3">
            <p className="warning">{warning}</p>
          </div>

          {extend ? (
            <ExtendBooking
              booking={booking}
              setExtend={setExtend}
              onComplete={fetchBookingData}
            />
          ) : (
            <Fragment>
              <div
                onClick={(e) =>
                  navigate(
                    `/payments/add?customerId=${booking.customer.id}&bookingId=${booking.id}`,
                    e
                  )
                }
                className="btn-secondary"
              >
                Charge Customer
              </div>
              <div onClick={() => setExtend(true)} className="btn-secondary">
                Change End Date
              </div>
              {booking.agreement ? (
                <a
                  href={booking.agreement}
                  target="_blank"
                  className="btn-secondary"
                >
                  Download Agreement
                </a>
              ) : (
                <div onClick={forceAgreementHandler} className="btn-secondary">
                  Force Agreement
                </div>
              )}
              <div
                onClick={(e) =>
                  navigate(
                    `/tolls/booking?customerId=${booking.customer.id}&bookingId=${booking.id}`,
                    e
                  )
                }
                className="btn-secondary"
              >
                View Tolls
              </div>
              <div
                onClick={(e) =>
                  navigate(
                    `/charging/booking?customerId=${booking.customer.id}&bookingId=${booking.id}`,
                    e
                  )
                }
                className="btn-secondary"
              >
                View Charging
              </div>
              <div
                onClick={generateDemandLetterHandler}
                className="btn-secondary"
              >
                Generate Demand Letter
              </div>

              <div className="md:col-span-3">
                <Notes
                  collection="Bookings"
                  id={booking.id}
                  notes={booking.notes}
                  onComplete={fetchBookingData}
                />
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
}
