// External Imports
import { createContext } from "react";
import { useNavigate } from "react-router-dom";

/** ========================================================================
 * * Utility Context
 * This context provides utility functions to the app that require using hooks.
 * This is useful for functions that are used in multiple components.
 * ========================================================================== */

// Create Context
export const UtilityContext = createContext({
  navigate: () => {},
  goBack: () => {},
});

// Context Provider
export const UtilityContextProvider = ({ children }) => {
  // Initialize Hooks
  const navigation = useNavigate();

  // * Utility Functions

  // Navigate to a new page
  const navigate = (path, e, options = {}) => {
    if (e?.ctrlKey || e?.metaKey) window.open(path, "_blank");
    else navigation(path, options);
  };

  // Navigate Back
  const goBack = () => navigation(-1);

  return (
    <UtilityContext.Provider value={{ navigate, goBack }}>
      {children}
    </UtilityContext.Provider>
  );
};
