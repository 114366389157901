// Internal Imports
import { useContext } from "react";
import { UtilityContext } from "../../context/util-context";
import { timestampToString } from "../../util/timestamp-to-date";
import { truncate } from "../../util/format-text";

/** ========================================================================
 * * Booking Card
 * Renders a card to summarize booking details.
 * ========================================================================== */

export default function BookingCard({ booking, customer }) {
  const { navigate } = useContext(UtilityContext);
  
  const classNames = () => {
    let classes =
      "grid items-center grid-cols-2 gap-2 text-center md:text-start md:text-sm md:grid-cols-4 pill";
    if (booking.status === "cancelled") classes += " border-blue-600";
    if (customer?.unpaid_invoices) {
      classes += " border-red-600";
    }
    return classes;
  };

  return (
    <div
      onClick={(e) => navigate(`/bookings/info?id=${booking.id}`, e)}
      className={classNames()}
    >
      <img alt="Vehicle" src={booking.vehicle.image} className="md:w-28" />
      <div className="grid">
        <p className="font-semibold text-stone-200">
          {booking.customer.firstName} {booking.customer.lastName}
        </p>
        <p className="text-stone-400">
          {truncate(`${booking.vehicle.model} ${booking.vehicle.license}`, 32)}
        </p>
      </div>
      <div className="hidden md:grid text-stone-200">
        <p>{timestampToString(booking.start_date, "do MMM hh:mm a")}</p>
        <p>{timestampToString(booking.end_date, "do MMM hh:mm a")}</p>
      </div>
      <div className="hidden md:grid text-stone-400">
        <p>{truncate(booking.pick_up_location, 32)}</p>
        <p>{truncate(booking.drop_off_location, 32)}</p>
      </div>
    </div>
  );
}
