import { useState, Fragment } from "react";
export default function DropDownBanner({ children, data, onClick }) {
  const [show, setShow] = useState(false);

  const clickHandler = () => {
    setShow(!show);
    onClick && onClick();
  };
  return (
    <Fragment>
      {show && (
        <div
          onClick={() => setShow(false)}
          className="absolute w-screen h-screen"
        ></div>
      )}
      <div className="relative cursor-pointer" onClick={clickHandler}>
        {children}
        {show && (
          <div className="absolute grid gap-2 p-4 overflow-scroll rounded-lg max-h-52 bg-stone-800 no-scrollbar">
            {data.map((item, indx) =>
              Object.keys(item).map((key, idx) => (
                <div key={idx} className="text-xs text-stone-200">
                  {key.toLowerCase() === "link" ? (
                    <a
                      href={item[key]}
                      className="underline cursor-pointer text-primary"
                      target="_blank"
                    >
                      {indx+1}. Link
                    </a>
                  ) : (
                    <Fragment>
                      <p className="text-stone-400">{key}:</p>
                      <p>{item[key]}</p>
                    </Fragment>
                  )}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
}
