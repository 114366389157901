// External Imports
import { useSearchParams } from "react-router-dom";
import Heading from "../UI/Heading";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import { useContext, useEffect, useState, Fragment } from "react";
import { UtilityContext } from "../../context/util-context";
import DetailPair from "../UI/DetailPair";

// Internal Imports
import { AuthContext } from "../../context/auth-context";
import { timestampToString } from "../../util/timestamp-to-date";
import PartnerVehicleCard from "./PartnerVehicleCard";
import { FaEdit } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import LinkPair from "../UI/LinkPair";
import uploadFile from "../../util/upload-file";
import CreditApplication from "./CreditApplication";
import { MdRefresh } from "react-icons/md";
import { DataContext } from "../../context/data-context";
import PartnerPayments from "./PartnerPayments";
import VehicleRevenue from "../general-page/VehicleRevenue";
import { requestAdminAPI } from "../../api/posh-api";

export default function PartnerInfo() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { user } = useContext(AuthContext);
  const { fetchPartners, vehicles } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState();
  const [uploading, setUploading] = useState(false);
  const [item, setItem] = useState();
  const [payments, setPayments] = useState(false);
  const [creditApp, setCreditApp] = useState(false);
  const [edit, setEdit] = useState(false);

  // Calculate Monthly Payments
  let activeVehicles = 0;
  let activeMonthyPayments = 0;
  let inactiveMonthlyPayments = 0;
  let averageMonthlyPayments = 0;
  if (partner) {
    partner.vehicles.forEach((vehicle) => {
      if (!vehicle.monthly) return;
      if (vehicle.status === "active") {
        activeMonthyPayments += Number(vehicle.monthly);
        activeVehicles++;
      } else inactiveMonthlyPayments += Number(vehicle.monthly);
    });
    averageMonthlyPayments =
      Number(activeMonthyPayments) / Number(activeVehicles || 1);
    activeMonthyPayments = activeMonthyPayments.toFixed(2);
    inactiveMonthlyPayments = inactiveMonthlyPayments.toFixed(2);
    averageMonthlyPayments = averageMonthlyPayments.toFixed(2);
  }

  // Calculate Current Utilization Rate
  let totalBooked = 0;
  if (partner) {
    vehicles.forEach((vehicle) => {
      if (vehicle.partnerId === partner.id)
        if (vehicle.bookings?.length > 0) totalBooked++;
    });
  }
  let utilizationRate = (totalBooked * 100) / (activeVehicles || 1);
  utilizationRate = utilizationRate.toFixed(2);

  // ========================================= FETCH PARTNER
  const fetchPartnerHandler = async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("fetch-partner", {
        partnerId: id,
      });
      setPartner(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // ========================================= EDIT PARTNER
  const editHandler = async () => {
    try {
      setLoading(true);
      const updates = {
        phone: document.getElementById("Phone").value,
        address: document.getElementById("Address").value,
        dob: document.getElementById("Date of Birth").value,
      };

      const files = {
        agreement: document.getElementById("Agreement").files[0],
        drivers_license_front: document.getElementById("Driver's License Front")
          .files[0],
        drivers_license_back: document.getElementById("Driver's License Back")
          .files[0],
      };

      // Upload Files
      if (files.agreement) {
        const path = `partners/${user.uid}/agreement.${files.agreement.name
          .split(".")
          .pop()}`;
        setItem("Agreement");
        updates.agreement = await uploadFile(
          path,
          files.agreement,
          setUploading
        );
        setUploading(false);
      }
      if (files.drivers_license_front) {
        const path = `partners/${
          user.uid
        }/drivers_license_front.${files.drivers_license_front.name
          .split(".")
          .pop()}`;
        setItem("Driver's License Front");
        updates.drivers_license_front = await uploadFile(
          path,
          files.drivers_license_front,
          setUploading
        );
        setUploading(false);
      }
      if (files.drivers_license_back) {
        const path = `partners/${
          user.uid
        }/drivers_license_back.${files.drivers_license_back.name
          .split(".")
          .pop()}`;
        setItem("Driver's License Back");
        updates.drivers_license_back = await uploadFile(
          path,
          files.drivers_license_back,
          setUploading
        );
        setUploading(false);
      }

      // Upload Changes
      await requestAdminAPI("edit-partner", {
        partnerId: partner.id,
        items: updates,
      });
      setEdit(false);
      fetchPartnerHandler();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPartnerHandler();
  }, []);

  if (!id)
    return (
      <div className="container">
        <p className="text-stone-400">Partner ID Not Provided!</p>
      </div>
    );

  if (!loading && !partner)
    return (
      <div className="container">
        <p className="text-stone-400">Partner Not Found!</p>
      </div>
    );

  if (creditApp)
    return <CreditApplication partner={partner} setOpen={setCreditApp} />;
  if (payments)
    return <PartnerPayments partnerId={partner.id} setOpen={setPayments} />;

  return (
    <div className="container">
      <Heading title="Partner Information">
        {edit ? (
          <Fragment>
            <FiCheck
              onClick={editHandler}
              className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
            />
            <AiOutlineClose
              onClick={() => setEdit(false)}
              className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
            />
          </Fragment>
        ) : (
          <Fragment>
            <MdRefresh
              className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
              onClick={fetchPartners}
            />
            <FaEdit
              onClick={() => setEdit(true)}
              className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
            />
          </Fragment>
        )}
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="grid content-start gap-8">
          <VehicleRevenue
            vehicles={
              partner.vehicles ? partner.vehicles?.filter((v) => v.id) : []
            }
            forPartner={true}
          />
          <div className="info">
            <div className="flex items-center space-x-4">
              {partner.photoURL && (
                <img src={partner.photoURL} className="w-12 h-12 rounded-lg" />
              )}
              <DetailPair label="Name" value={partner.name} />
            </div>
            <DetailPair label="ID" value={partner.id} />
            <DetailPair
              label="Date Created"
              value={timestampToString(partner.date_created, "MMM do yyyy")}
            />
            <DetailPair label="Email" value={partner.email} />
            <DetailPair label="Phone" value={partner.phone} edit={edit} />
            <DetailPair label="Date of Birth" value={partner.dob} edit={edit} />
            <DetailPair label="Address" value={partner.address} edit={edit} />
            <LinkPair label="Agreement" link={partner.agreement} edit={edit} />
            <LinkPair
              label="Driver's License Front"
              link={partner.drivers_license_front}
              edit={edit}
            />
            <LinkPair
              label="Driver's License Back"
              link={partner.drivers_license_back}
              edit={edit}
            />
            <DetailPair
              label="Active Monthly Installments"
              value={`$${activeMonthyPayments}`}
            />
            <DetailPair
              label="Inactive Monthly Installments"
              value={`$${inactiveMonthlyPayments}`}
            />
            {partner.vehicles?.length > 0 && (
              <Fragment>
                <DetailPair
                  label="Average Monthly Installments"
                  value={`$${averageMonthlyPayments}`}
                />
                <DetailPair
                  label="Utilization Rate"
                  value={`${utilizationRate}%`}
                />
              </Fragment>
            )}
          </div>
          <div className="info">
            <div onClick={() => setCreditApp(true)} className="btn-secondary">
              View Credit App
            </div>
            <div onClick={() => setPayments(true)} className="btn-secondary">
              View Payments
            </div>
          </div>
          <div className="grid gap-2">
            <Heading title="Vehicles" isBackArrow={false}>
              <BsPlusLg
                onClick={(e) =>
                  navigate(`/partners/vehicle?partnerId=${partner.id}`, e)
                }
                className="text-lg text-white cursor-pointer hover:text-stone-400"
              />
            </Heading>
            <div className="list max-h-[520px]">
              {partner.vehicles.map((vehicle, idx) => (
                <PartnerVehicleCard
                  key={idx}
                  vehicle={vehicle}
                  onClick={(e) =>
                    navigate(
                      `/partners/vehicle?partnerId=${partner.id}&vin=${vehicle.vin}`,
                      e
                    )
                  }
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {uploading && (
        <p>
          Uploading {item}: {uploading}%
        </p>
      )}
    </div>
  );
}
