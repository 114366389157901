// External Packages
import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BsFilter, BsPlusLg } from "react-icons/bs";
import { RiSteeringFill } from "react-icons/ri";
import { FiDownload } from "react-icons/fi";
import { MdRefresh } from "react-icons/md";
import { BiWorld } from "react-icons/bi";

// Internal Packages
import { DataContext } from "../../context/data-context";
import { AuthContext } from "../../context/auth-context";
import { UtilityContext } from "../../context/util-context";
import { requestAdminAPI } from "../../api/posh-api";
import { arrayToCsv } from "../../util/arrayToCsv";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import SearchBar from "../UI/SearchBar";
import VehicleCard from "./VehicleCard";
import Heading from "../UI/Heading";
import FilterMenu from "../UI/FilterMenu";
import PaginationMenu from "../UI/PaginationMenu";

export default function VehiclesPage() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Load Context
  const { vehicles, loadingData, fetchVehicles, partners } =
    useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Default Filters
  const defaultFilter = {
    search: params.get("search") ?? "",
    location: params.get("location") ?? "All",
    booking: params.get("booking") ?? "All",
    available: params.get("available") ?? "All",
    tracker: params.get("tracker") ?? "All",
  };

  // Initialize States
  const [filteredVehicles, setFilteredVehicles] = useState(vehicles);
  const [filter, setFilter] = useState({ ...defaultFilter });

  /* ======================= APPLY FILTER CUSTOMERS ======================= */
  const applyFilters = () => {
    let records = [...vehicles];
    // Apply Tab selection filter
    records = tabsFilter(records);
    //Apply search filter
    if (filter.search) {
      records = searchVehicle(records);
    }
    setFilteredVehicles([...(records ?? [])]);
  };

  // Filter Dropdown options
  const tabsFilter = (data) => {
    let records = [...data];
    const filtered = [];
    records.forEach((vehicle) => {
      let flag = true;
      // Location Filter
      if (filter.location !== "All" && vehicle.location !== filter.location)
        flag = false;

      // Booking Filter
      if (
        filter.booking === "Booked" &&
        (!vehicle.bookings || vehicle.bookings.length === 0)
      )
        flag = false;
      if (
        filter.booking === "Not Booked" &&
        vehicle.bookings &&
        vehicle.bookings.length > 0
      )
        flag = false;

      // Availablility Filter
      if (filter.available === "Available" && !vehicle.status.available)
        flag = false;
      if (filter.available === "Not Available" && vehicle.status.available)
        flag = false;

      // Tracker filter
      if (filter.tracker === "Installed" && !vehicle.samsaraId) flag = false;
      if (filter.tracker === "Not Installed" && vehicle.samsaraId) flag = false;

      if (flag) filtered.push(vehicle);
    });
    return [...filtered];
  };

  // =================== SEARCH VEHICLES ===================
  const searchVehicle = (records) => {
    return records.filter((vehicle) => {
      const string =
        `${vehicle.make} ${vehicle.model} ${vehicle.year} ${vehicle.vin} ${vehicle.license} 
      ${vehicle.location} ${vehicle.plan.name} ${vehicle.license_state} ${vehicle.status.available}`.toLowerCase();
      return string.includes(filter.search.toLowerCase());
    });
  };

  // Fetch Vehicles
  useEffect(() => {
    applyFilters();
  }, [filter]);

  // =================== FILTER HANDLER ===================
  const filterOptions = [
    {
      label: "Location",
      options: ["All", "Boston, MA", "Dallas, TX"],
      selected: filter.location,
    },
    {
      label: "Booking",
      options: ["All", "Booked", "Not Booked"],
      selected: filter.booking,
    },
    {
      label: "Available",
      options: ["All", "Available", "Not Available"],
      selected: filter.available,
    },
    {
      label: "Tracker",
      options: ["All", "Installed", "Not Installed"],
      selected: filter.tracker,
    },
  ];
  const filterHandler = (filters) => {
    const { location, booking, available, tracker } = filters;
    navigate(
      `/vehicles?search=${filter.search}&location=${location}&booking=${booking}&available=${available}&tracker=${tracker}`,
      {},
      { replace: true }
    );
    setFilter({ ...filter, location, booking, available, tracker });
  };

  // =================== REFRESH DATA ===================
  const refreshHandler = () => {
    fetchVehicles();
  };

  // =================== DOWNLOAD CSV ===================
  const downloadCSV = () => {
    const formatted = filteredVehicles.map((vehicle) => {
      const partner = partners.filter((p) => p.id === vehicle.partnerId)[0];
      return {
        id: vehicle.id,
        vehicle: `${vehicle.make} ${vehicle.model} ${vehicle.year}`,
        vin: vehicle.vin,
        license: `${vehicle.license}`,
        state: vehicle.license_state,
        partner: partner?.name || "",
        tracker: vehicle.samsaraId ? "Y" : "N", 
      };
    });
    arrayToCsv(formatted, "posh-vehicles");
  };

  // =================== SET UP SMARTCAR ===================
  const setUpSmartcar = async () => {
    try {
      const response = await requestAdminAPI("get-smartcar-connect-link", {});
      window.open(response.link, "_blank");
    } catch (err) {
      console.error(err);
    }
  };

  // Clear Search Filter
  const clearSearch = () => {
    const { location, booking, available, tracker } = filter;
    setFilter({ ...filter, search: "" });
    navigate(
      `/vehicles?search=&location=${location}&booking=${booking}&available=${available}&tracker=${tracker}`,
      {},
      { replace: true }
    );
  };
  // Search handler
  const searchHandler = (value) => {
    const { location, booking, available, tracker } = filter;
    navigate(
      `/vehicles?search=${value}&location=${location}&booking=${booking}&available=${available}&tracker=${tracker}`,
      {},
      { replace: true }
    );

    setFilter({ ...filter, search: value });
  };

  return (
    <div className="container grid">
      <Heading title="Vehicles" isBackArrow={false}>
        <FilterMenu onSubmit={filterHandler} options={filterOptions}>
          <BsFilter className="icon" />
        </FilterMenu>
        <BsPlusLg
          onClick={(e) => navigate("/vehicles/add", e)}
          className="icon"
          title="Add Vehicle"
        />
        <BiWorld
          onClick={(e) => navigate("/brands", e)}
          className="icon"
          title="Add Brand"
        />
        <RiSteeringFill
          onClick={setUpSmartcar}
          className="icon"
          title="Set up Smartcar"
        />
        <FiDownload
          onClick={downloadCSV}
          className="icon"
          title="Download VINs"
        />
        <MdRefresh
          onClick={refreshHandler}
          className="icon"
          title="Refresh Data"
        />
      </Heading>

      <SearchBar
        searchHandler={searchHandler}
        clearSearch={clearSearch}
        searchValue={filter.search}
      />

      <div className="list">
        {loadingData ? (
          <LoadingSpinnerWrapper />
        ) : (
          <div className="grid gap-8">
            {filteredVehicles.map((vehicle) => (
              <VehicleCard vehicle={vehicle} key={vehicle.id} />
            ))}
          </div>
        )}
      </div>

      <PaginationMenu
        showing={filteredVehicles.length}
        total={vehicles.length}
      />
    </div>
  );
}
