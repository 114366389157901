// External Imports
import { useState, useEffect, useContext, Fragment } from "react";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { FiCheck } from "react-icons/fi";
import { FaEdit } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

// Internal Imports
import { AuthContext } from "../../context/auth-context";
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { requestAdminAPI } from "../../api/posh-api";
import { toTitleCase } from "../../util/toTitleCase";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import LinkPair from "../UI/LinkPair";
import uploadFile from "../../util/upload-file";
import LoadingSpinner from "../UI/LoadingSpinner";

/** ========================================================================
 * * Partner Vehicle Page
 * Renders a page, which allows the user to add or view a partner's vehicle.
 * ========================================================================== */

export default function PartnerVehicle() {
  // Load Context
  const { user } = useContext(AuthContext);
  const { fetchPartners, brands } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Get Partner ID and Vehicle VIN from URL
  const [searchParams] = useSearchParams();
  const partnerId = searchParams.get("partnerId");
  const vin = searchParams.get("vin");

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [partner, setPartner] = useState();
  const [vehicle, setVehicle] = useState();
  const [edit, setEdit] = useState(vin ? false : true);
  const [uploading, setUploading] = useState(false);
  const [item, setItem] = useState();

  /* ======================= FETCH PARTNER ======================= */
  const fetchPartnerHandler = async () => {
    try {
      setLoading(true);
      // Fetch Partner
      const data = await requestAdminAPI("fetch-partner", { partnerId });
      setPartner(data);
      // Find Vehicle
      const vehicle = data?.vehicles.filter((v) => v.vin === vin)[0];
      setVehicle(vehicle);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  /* ======================= VIN INPUT HANDLER =======================
  This function is used to decode the VIN using POSH's API
  and set the resulting vehicle information in the form 
  */
  const vinInputHandler = async (vin) => {
    try {
      // Check if VIN is complete
      if (vin.length !== 17) return;

      // Send request to decode VIN
      const response = await requestAdminAPI("decode-vin", { vin });
      const vehicleInfo = response.vehicleInfo;

      // Check if brand is added
      if (
        !brands.find(
          (brand) => brand.name.toLowerCase() === vehicleInfo.make.toLowerCase()
        )
      )
        throw new Error(
          `Brand (${vehicleInfo.make}) not found. Please add the brand first.`
        );

      // Set Results
      Object.keys(vehicleInfo).forEach((key) => {
        if (key === "vin") return;
        if (document.getElementById(key))
          document.getElementById(key).value = vehicleInfo[key];
      });
    } catch (err) {
      console.error(err);
      setWarning(err.message);
    }
  };

  /* ======================= UPDATE PARTNER VEHICLE =======================
  Allows admin to add or update a partner's vehicle.
  */
  const updatePartnerVehicle = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setWarning();

      // If vehicle already exists, use it as payload
      let payload;
      if (vehicle) payload = { partnerId: partner.id, ...vehicle };
      // If not, create a new payload object
      else
        payload = {
          partnerId: partner.id,
          vin: document.getElementById("vin")?.value,
          make: document.getElementById("make")?.value,
          model: document.getElementById("model")?.value,
          year: document.getElementById("year")?.value,
        };

      /*
      * Add Optional Fields
      Check which field is provided and add it to the payload.
      */
      [
        "license",
        "license_state",
        "institution",
        "amount",
        "term",
        "monthly",
        "due",
      ].map((name) => {
        const value = document.getElementById(name)?.value;
        value && (payload[name] = value);
      });

      /*
      * Upload Files
      Files are directly uploaded to firebase storage and the
      download URL is attached to the payload object.

      Check which file is provided, upload it and attach the download URL to the payload
      */
      await Promise.all(
        [
          "bos",
          "title",
          "registeration",
          "vir",
          "insurance",
          "poa",
          "loan",
          "agreement",
        ].map(async (name) => {
          const doc = document.getElementById(name);
          if (doc?.files[0]) {
            const path = `partners/${user.uid}/vehicles/${
              payload.vin
            }/${name}.${doc.files[0].name.split(".").pop()}`;
            setItem(name);
            payload[name] = await uploadFile(path, doc.files[0], setUploading);
            setUploading(false);
          }
        })
      );

      // Send API Request
      await requestAdminAPI("update-partner-vehicle", payload);

      // Refresh and navigate back to the partner info page
      fetchPartners(() =>
        navigate(`/partners/vehicle?partnerId=${partner.id}&vin=${vin}`)
      );
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  /* ======================= REASSIGN DOCUMENT HANDLER =======================
  Allows admin to reassign a power of attorney or vehicle agreement after 
  changes are made by deleting the existing document.
   */
  const reassignDocumentHandler = async (key) => {
    try {
      setLoading(true);
      // Send Request
      await requestAdminAPI("update-partner-vehicle", {
        partnerId: partner.id,
        ...vehicle,
        toDelete: key,
      });
      // Refresh and navigate back to the partner info page
      fetchPartners(() =>
        navigate(`/partners/vehicle?partnerId=${partner.id}&vin=${vin}`)
      );
    } catch (err) {
      console.error(err);
      setLoading(false);
      setWarning(err.message);
    }
  };

  /* ======================= ACTIVATE/DEACTIVATE VEHICLE ======================= */
  const activateDeactivateHandler = async (status) => {
    try {
      setLoading(true);
      // Send Request
      await requestAdminAPI("activate-partner-vehicle", {
        partnerId: partner.id,
        vin: vehicle.vin,
        status: status,
      });
      fetchPartners(
        () => `/partners/vehicle?partnerId=${partner.id}&vin=${vin}`
      );
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setWarning(err.message);
    }
  };

  // Fetch Partner on Load
  useEffect(() => {
    fetchPartnerHandler();
  }, []);

  return (
    <div className="container">
      <Heading
        title={vehicle ? "Partner Vehicle Details" : "Add Partner Vehicle"}
        text="Once a vehicle is added, the VIN number or vehicle make, model and year 
      cannot be changed. To revise such information, delete the vehicle and add it again."
      >
        {edit ? (
          <Fragment>
            <FiCheck
              onClick={updatePartnerVehicle}
              className="text-lg text-white cursor-pointer md:block hover:text-stone-400"
            />
            {vehicle && (
              <AiOutlineClose
                onClick={() => setEdit(false)}
                className="text-lg text-white cursor-pointer hover:text-stone-400"
              />
            )}
          </Fragment>
        ) : (
          <FaEdit
            onClick={() => setEdit(true)}
            className="text-lg text-white cursor-pointer hover:text-stone-400"
          />
        )}
      </Heading>

      <div className="grid">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Fragment>
            <div className="info md:grid-cols-4">
              {vehicle && (
                <DetailPair
                  label="ID"
                  value={vehicle?.id}
                  link={vehicle?.id ? `/vehicles/info?id=${vehicle.id}` : false}
                />
              )}
              <div className="grid">
                <label className="form-label">VIN:</label>
                {vehicle ? (
                  <p className="text-stone-200">{vehicle.vin}</p>
                ) : (
                  <input
                    className="form-input"
                    id="vin"
                    required={true}
                    onChange={(e) => vinInputHandler(e.target.value)}
                  />
                )}
              </div>
              <DetailPair
                label="Make"
                id="make"
                value={vehicle?.make}
                edit={edit}
                disabled={true}
              />
              <DetailPair
                label="Model"
                id="model"
                value={vehicle?.model}
                edit={edit}
                disabled={true}
              />
              <DetailPair
                label="Year"
                id="year"
                value={vehicle?.year}
                edit={edit}
                disabled={true}
              />
              <DetailPair
                label="License"
                id="license"
                value={vehicle?.license}
                edit={edit}
              />
              <DetailPair
                label="License State"
                id="license_state"
                value={vehicle?.license_state}
                edit={edit}
              />
              <LinkPair
                label="Bill of Sale"
                id="bos"
                link={vehicle?.bos}
                edit={edit}
              />
              <LinkPair
                label="Title"
                id="title"
                link={vehicle?.title}
                edit={edit}
              />
              <LinkPair
                label="Registeration"
                id="registeration"
                link={vehicle?.registeration}
                edit={edit}
              />
              <LinkPair
                label="Inspection Report"
                id="vir"
                link={vehicle?.vir}
                edit={edit}
              />
              <LinkPair
                label="Insurance"
                id="insurance"
                link={vehicle?.insurance}
                edit={edit}
              />
              <DetailPair
                label="Financing Institution"
                id="institution"
                value={vehicle?.institution}
                edit={edit}
              />
              <DetailPair
                label="Finance Amount"
                id="amount"
                value={vehicle?.amount}
                edit={edit}
              />
              <DetailPair
                label="Term (Months)"
                id="term"
                value={vehicle?.term}
                edit={edit}
              />
              <DetailPair
                label="Monthly Payment"
                id="monthly"
                value={vehicle?.monthly}
                edit={edit}
              />
              <LinkPair
                label="Loan Document"
                id="loan"
                link={vehicle?.loan}
                edit={edit}
              />
              <DetailPair
                label="Due Date"
                id="due"
                value={
                  vehicle &&
                  `${
                    vehicle.due
                      ? format(new Date(vehicle.due), "yyyy-MM-dd")
                      : "-"
                  }`
                }
                edit={edit}
                type={"date"}
              />

              <LinkPair
                label="Power of Attorney"
                id="poa"
                link={vehicle?.poa}
                edit={edit}
              />
              <LinkPair
                label="Vehicle Agreement"
                id="agreement"
                link={vehicle?.agreement}
                edit={edit}
              />

              {vehicle && (
                <DetailPair
                  label="Status"
                  value={toTitleCase(vehicle.status)}
                />
              )}
            </div>
            {!edit && (
              <div className="mt-12 info">
                {vehicle?.poa && (
                  <button
                    onClick={() => reassignDocumentHandler("poa")}
                    className="btn-secondary"
                  >
                    Reassign POA
                  </button>
                )}
                {vehicle?.agreement && (
                  <button
                    onClick={() => reassignDocumentHandler("agreement")}
                    className="btn-secondary"
                  >
                    Reassign Agreement
                  </button>
                )}
                {vehicle?.status !== "active" ? (
                  <button
                    onClick={() => activateDeactivateHandler("active")}
                    className="btn-primary"
                  >
                    Activate Vehicle
                  </button>
                ) : (
                  <button
                    onClick={() => activateDeactivateHandler("inactive")}
                    className="btn-warning"
                  >
                    Deactivate Vehicle
                  </button>
                )}
              </div>
            )}
          </Fragment>
        )}
        <div className="mt-12 info">
          {warning && <p className="warning">WARNING: {warning}</p>}
          {uploading && (
            <p className="text-stone-400">
              Uploading {item}: {uploading}%
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
