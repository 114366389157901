import { Fragment, useContext } from "react";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import MenuBar from "./MenuBar";
import { DataContext } from "../../context/data-context";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";

export default function Layout({ children }) {
  const { loadingData } = useContext(DataContext);

  return (
    <Fragment>
      <Navbar />
      <MenuBar />
      <div className="grid grid-cols-5 gap-4 mx-4 mb-4">
        <Fragment>
          <Sidebar />
          <div className="col-span-5 md:col-span-4">
            {loadingData ? <div className="h-screen">
              <LoadingSpinnerWrapper />
            </div> :  children }
          </div>
        </Fragment>
      </div>
      <Footer />
    </Fragment>
  );
}
