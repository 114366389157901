import { timestampToString } from "../../util/timestamp-to-date";

export default function InvoiceCard({ invoice }) {
  return (
    <div className="grid grid-cols-4 text-sm pill text-stone-200">
      <p>{invoice.requester?.name}</p>
      <p>${invoice.amount}</p>
      <p>{timestampToString(invoice.date_created, "MM/dd/yyyy hh:mm a")}</p>
      <p>{invoice.description}</p>
    </div>
  );
}
