import Heading from "../UI/Heading";
import { FaLock, FaUnlock, FaGasPump } from "react-icons/fa";
import { MdMyLocation } from "react-icons/md";
import { IoIosSpeedometer } from "react-icons/io";
import { useContext, useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import { sendControlSignal } from "../../api/fetch";
import { AuthContext } from "../../context/auth-context";
import { toTitleCase } from "../../util/toTitleCase";

export default function VehicleControls({ vehicle }) {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [warning, setWarning] = useState();

  const sendSignal = async (action) => {
    try {
      setLoading(true);
      setWarning();
      const response = await sendControlSignal(user, {
        vehicleId: vehicle.id,
        action: action,
      });
      if (response.data) setData(`${toTitleCase(action)}: ${response.data}`);
      else setData(response.status);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setData();
      setLoading(false);
    }
  };

  return (
    <div>
      <Heading
        title="Vehicle Controls"
        text={
          "BETA WARNING: This feature is currently under development - some actions might not work."
        }
      ></Heading>

      {loading ? (
        <div className="mt-6">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex items-center mt-6 space-x-6">
          <FaLock onClick={() => sendSignal("lock")} className="text-2xl cursor-pointer text-stone-200 hover:text-stone-400" />
          <FaUnlock onClick={() => sendSignal("unlock")} className="text-2xl cursor-pointer text-stone-200 hover:text-stone-400" />
          <MdMyLocation onClick={() => sendSignal("location")} className="text-2xl cursor-pointer text-stone-200 hover:text-stone-400" />
          <IoIosSpeedometer onClick={() => sendSignal("odometer")} className="text-2xl cursor-pointer text-stone-200 hover:text-stone-400" />
          <FaGasPump onClick={() => sendSignal("fuel")} className="text-2xl cursor-pointer text-stone-200 hover:text-stone-400" />
          {data && <p className="text-stone-400 form-input">{data}</p>}
          {warning && <p className="text-red-800">{warning}</p>}
        </div>
      )}
    </div>
  );
}
