import { useState, Fragment } from "react";
export default function Switch({ label, value, onSwitch, edit, id }) {
  const [on, setOn] = useState(value);

  const button = () => {
    let classes =
      "w-6 h-6 rounded-full bg-stone-200 transition-all duration-500";
    if (on) {
      return classes + " translate-x-6 transform";
    } else return classes;
  };

  const background = () => {
    let classes =
      "flex items-center w-12 h-6 cursor-pointer transition-all duration-500 bg-stone-400 rounded-full";
    if (on) {
      return classes + " bg-green-600";
    } else return classes;
  };

  const switchHandler = () => {
    if (!edit) return;
    setOn(!on);
    onSwitch && onSwitch(!on);
  };

  return (
    <Fragment>
      <p className="text-stone-400">{label}:</p>
      <div onClick={switchHandler} className={background()}>
        <div className={button()}></div>
        <input id={id || label} type="checkbox" name={id || label} hidden checked={on} readOnly />
      </div>
    </Fragment>
  );
}
