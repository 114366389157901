import { useState, Fragment } from "react";
import MenuItem from "./MenuItem";
import { AiOutlineMenu } from "react-icons/ai";

export default function MenuBar() {
  const [selected, setSelected] = useState("General");
  const [showMenu, setShowMenu] = useState(false);

  const selectionHandler = (selected) => {
    setSelected(selected);
    setShowMenu(false);
  };

  return (
    <div className="relative mx-4 mb-4 md:hidden">
      <div
        onClick={() => setShowMenu(!showMenu)}
        className="flex items-center p-2 rounded-lg shadow-md cursor-pointer bg-stone-900"
      >
        <AiOutlineMenu className="text-stone-400" />
        <p className="py-1 mx-auto text-sm font-semibold text-center text-stone-400">
          {selected}
        </p>
      </div>
      {showMenu && (
        <Fragment>
          <div className="absolute z-50 w-full p-4 mt-4 font-semibold rounded-lg rounded-b-lg md:w-fit bg-stone-900">
            <MenuItem setSelected={selectionHandler} title={"General"} />
            <MenuItem setSelected={selectionHandler} title={"Bookings"} />
            <MenuItem setSelected={selectionHandler} title={"Customers"} />
            <MenuItem setSelected={selectionHandler} title={"Partners"} />
            <MenuItem setSelected={selectionHandler} title={"Vehicles"} />
            <MenuItem setSelected={selectionHandler} title={"Payments"} />
            <MenuItem setSelected={selectionHandler} title={"Tolls"} />
            <MenuItem setSelected={selectionHandler} title={"Charging"} />
            <MenuItem setSelected={selectionHandler} title={"Expenses"} />
          </div>
          <div
            className="absolute w-screen h-screen "
            onClick={() => setShowMenu(false)}
          ></div>
        </Fragment>
      )}
    </div>
  );
}
