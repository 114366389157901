// External Packages
import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BsFilter, BsPlusLg } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import { BiDownload } from "react-icons/bi";

// Internal Packages
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { timestampToString } from "../../util/timestamp-to-date";
import { arrayToCsv } from "../../util/arrayToCsv";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import SearchBar from "../UI/SearchBar";
import PaymentCard from "./PaymentCard";
import Heading from "../UI/Heading";
import FilterMenu from "../UI/FilterMenu";
import PaginationMenu from "../UI/PaginationMenu";

/*
  * All Payments Page
  This page displays all payments in the database.
*/

export default function PaymentsPage() {
  const { navigate } = useContext(UtilityContext);
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Load Context
  const { payments, loadingData, fetchPayments } = useContext(DataContext);

  // Default Filter
  const defaultFilter = {
    search: params.get("search") ?? "",
    type: params.get("type") ?? "All",
    status: params.get("status") ?? "All",
  };

  // Initialize States
  const [filteredPayments, setFilteredPayments] = useState(payments);
  const [filter, setFilter] = useState({ ...defaultFilter });

  /* ======================= APPLY FILTER CUSTOMERS ======================= */
  const applyFilters = () => {
    let records = [...payments];
    // Apply Tab selection filter
    records = tabsFilter(records);
    //Apply search filter
    if (filter.search) {
      records = searchPayment(records);
    }
    setFilteredPayments([...(records ?? [])]);
  };

  // Filter Dropdown options
  const tabsFilter = (data) => {
    let records = [...data];
    let filtered = [];
    records.forEach((payment) => {
      let flag = true;
      if (
        filter.status !== "All" &&
        payment.status !== filter.status.toLowerCase()
      )
        flag = false;
      if (filter.type !== "All" && payment.type !== filter.type.toLowerCase())
        flag = false;
      if (flag) filtered.push(payment);
    });
    return [...filtered];
  };

  //  =================== SEARCH PAYMENT HANDLER ===================
  const searchPayment = (records) => {
    return records.filter((payment) => {
      const string =
        `${payment.customer.firstName} ${payment.customer.lastName} ${payment.customer.email} 
    ${payment.customer.phone} ${payment.description} ${payment.amount} ${payment.vehicle?.make}
    ${payment.vehicle?.model} ${payment.vehicle?.license} ${payment.vehicle?.year}
    ${payment.vehicle?.vin}`.toLowerCase();
      return string.includes(filter.search.toLowerCase());
    });
  };

  // Fetch Payments and apply filter
  useEffect(() => {
    applyFilters();
  }, [filter]);

  // =================== FILTER HANDLER ===================
  const filterOptions = [
    {
      label: "Status",
      options: ["All", "Succeeded", "Pending", "Refunded"],
      selected: filter.status,
    },
    {
      label: "Type",
      options: ["All", "Payment", "Invoice"],
      selected: filter.type,
    },
  ];
  const filterHandler = (filters) => {
    const { type, status } = filters;
    navigate(
      `/payments?search=${filter.search}&type=${type}&status=${status}`,
      {},
      { replace: true }
    );

    setFilter({ ...filter, type, status });
  };

  // =================== DOWNLOAD CSV ===================
  const downloadCSV = () => {
    const arr = [];
    filteredPayments.forEach((payment) => {
      arr.push({
        id: payment.id,
        customer: `${payment.customer.firstName} ${payment.customer.lastName}`,
        email: payment.customer.email,
        phone: payment.customer.phone,
        description: payment.description,
        amount: payment.amount,
        date: timestampToString(payment.date_created, "MM/dd/yyyy hh:mm a"),
        due: timestampToString(payment.due_date, "MM/dd/yyyy hh:mm a"),
        status: payment.status,
      });
    });
    arrayToCsv(arr, "payments");
  };

  // Clear Search Filter
  const clearSearch = () => {
    const { type, status } = filter;
    setFilter({ ...filter, search: "" });
    navigate(
      `/payments?search=&type=${type}&status=${status}`,
      {},
      { replace: true }
    );
  };
  // Search handler
  const searchHandler = (value) => {
    const { type, status } = filter;
    navigate(
      `/payments?search=${value}&type=${type}&status=${status}`,
      {},
      { replace: true }
    );

    setFilter({ ...filter, search: value });
  };

  return (
    <div className="container grid">
      <Heading title="Payments" isBackArrow={false}>
        <FilterMenu options={filterOptions} onSubmit={filterHandler}>
          <BsFilter className="text-2xl text-white hover:text-stone-400" />
        </FilterMenu>
        <BiDownload
          onClick={downloadCSV}
          className="text-xl text-white cursor-pointer hover:text-stone-400"
        />
        <BsPlusLg
          onClick={(e) => navigate("/payments/add", e)}
          className="text-lg text-white cursor-pointer hover:text-stone-400"
          title="Add Payment"
        />
        <MdRefresh
          onClick={fetchPayments}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
          title="Refresh"
        />
      </Heading>

      <SearchBar
        searchHandler={searchHandler}
        clearSearch={clearSearch}
        searchValue={filter.search}
      />

      <div className="list">
        {loadingData ? (
          <LoadingSpinnerWrapper />
        ) : (
          <div className="grid gap-8">
            {filteredPayments.map((payment) => (
              <PaymentCard key={payment.id} payment={payment} />
            ))}
          </div>
        )}
      </div>

      <div className="flex justify-between text-sm text-stone-200">
        {filteredPayments.length !== payments.length && (
          <p>
            Total $
            {filteredPayments
              .reduce((acc, payment) => acc + Number(payment.amount), 0)
              .toFixed(2)}
          </p>
        )}
        <PaginationMenu
          showing={filteredPayments.length}
          total={payments.length}
        />
      </div>
    </div>
  );
}
