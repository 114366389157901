import { Fragment, useState } from "react";
import Heading from "../UI/Heading";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";

export default function BookingImages({ booking, setOpen }) {
  const [show, setShow] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const showImageHandler = (image) => {
    console.log("clicked");
    setShowImage(image);
    setShow(true);
  };

  return (
    <div className="container">
      <Transition appear show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShow}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-xl mx-4 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">
                  <div
                    className="absolute right-4 top-4"
                    onClick={() => setShow(false)}
                  >
                    <AiOutlineClose className="w-6 h-6 cursor-pointer text-stone-200" />
                  </div>
                  <div className="container">
                    <img src={showImage} alt="Booking Image" className="mx-auto md:max-w-[500px]"/>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Heading
        title="Booking Images"
        text="Images taken by admin and customer are shown collectively."
      />
      <div className="max-h-[540px] overflow-scroll no-scrollbar">
          <p className="mt-6 font-semibold text-stone-200">Check In Images:</p>
          <div className="grid gap-4 md:grid-cols-6">
            {booking?.check_in_images?.map((image, index) => (
              <img
                key={index}
                src={image}
                alt="Booking Image"
                className="object-cover w-40 h-40 rounded-lg cursor-pointer hover:opacity-75"
                onClick={() => showImageHandler(image)}
              />
            ))}
          </div>
          <p className="mt-6 font-semibold text-stone-200">Check Out Images:</p>
          <div className="grid gap-4 md:grid-cols-6">
            {booking?.check_out_images?.map((image, index) => (
              <img
                key={index}
                src={image}
                alt="Booking Image"
                className="object-cover w-40 h-40 rounded-lg cursor-pointer hover:opacity-75"
                onClick={() => showImageHandler(image)}
              />
            ))}
          </div>
      </div>
    </div>
  );
}
