// External Packages
import { Fragment, useContext, useState } from "react";

// Internal Packages
import { AuthContext } from "../../context/auth-context";
import { sendMessage } from "../../api/add";

// External Components
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";

// Internal Components
import LoadingSpinner from "../UI/LoadingSpinner";
import Heading from "../UI/Heading";

export default function MessageModal({ open, setOpen, customer }) {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setWarning(false);
    try {
      setLoading(true);
      const message = e.target.message.value;
      const response = await sendMessage(user, customer.id, message);
      setOpen(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-md mx-4 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">
                <div
                  className="absolute right-4 top-4"
                  onClick={() => setOpen(false)}
                >
                  <AiOutlineClose className="w-6 h-6 cursor-pointer text-stone-200" />
                </div>
                <div className="container">
                  <Heading title="Send Message" />
                  <form onSubmit={submitHandler} className="grid gap-4 mt-4">
                    <p className="text-sm text-stone-400">
                      Customer Phone: {customer?.phone || "-"}
                    </p>
                    <textarea
                      type="text"
                      name="message"
                      className="w-full form-input"
                    />
                    {loading ? (
                      <LoadingSpinner classes="w-8 h-8 text-yellow-600 mx-auto" />
                    ) : (
                      <div className="grid gap-4 md:flex md:space-x-2">
                        <button
                          type="button"
                          className="w-full btn-secondary"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="w-full btn-primary">
                          Send
                        </button>
                      </div>
                    )}
                    {warning && (
                      <p className="text-sm text-red-600">{warning}</p>
                    )}
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
