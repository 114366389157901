import { useContext } from "react";
import { UtilityContext } from "../../context/util-context";

export default function SelectPair({ label, value, options, edit, link, onChange }) {
  const { navigate } = useContext(UtilityContext);
  const clickHandler = (e) => {
    if (link) navigate(link, e);
  }
  const classNames = () => {
    if (link) return "cursor-pointer hover:opacity-70"
    else return "";
  }
  
  return (
    <div onClick={(e) => clickHandler(e)} className={classNames()}>
      <p className="text-stone-400">{label}:</p>
      {!edit && <p className="text-stone-200">{value || "-"}</p>}
      {edit && (
        <select
          className="w-full form-input"
          defaultValue={value || options[0]}
          name={label}
          id={label}
          onChange={onChange && onChange}
        >
          {options.map((option, idx) => (
            <option key={idx} value={typeof(option) === "object" ? option.id : option}>
              {typeof(option) === "object" ? option.name : option}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}
