// External Packages
import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Internal Packages
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";

// External Components
import {
  BsArrowLeftSquare,
  BsArrowRightSquare,
  BsFilter,
} from "react-icons/bs";
import { MdRefresh } from "react-icons/md";

// Internal Components
import CustomerCard from "./CustomerCard";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import SearchBar from "../UI/SearchBar";
import Heading from "../UI/Heading";
import { arrayToCsv } from "../../util/arrayToCsv";
import { BiDownload } from "react-icons/bi";
import FilterMenu from "../UI/FilterMenu";
import { IoMdPersonAdd } from "react-icons/io";
import { timestampToString } from "../../util/timestamp-to-date";

/*
  * All Customers Page
  This page displays all customers in the database.
*/

export default function CustomersPage() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  //Load Context
  const { customers, loadingData, fetchCustomers } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  const defaultFilter = {
    search: params.get("search") ?? "",
    location: params.get("location") ?? "All",
    booking: params.get("booking") ?? "All",
    plan: params.get("plan") ?? "All",
  };

  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const [filter, setFilter] = useState({ ...defaultFilter });

  /* ======================= APPLY FILTER CUSTOMERS ======================= */
  const applyFilters = () => {
    let records = [...customers];
    // Apply Tab selection filter
    records = tabsFilter(records);
    //Apply search filter
    if (filter.search) {
      records = searchUsers(records);
    }
    setFilteredCustomers([...(records ?? [])]);
  };

  // Filter Dropdown options
  const tabsFilter = (data) => {
    let records = [...data];
    const filtered = [];
    records.forEach((customer) => {
      let flag = true;
      if (
        filter.location !== "All" &&
        customer.state?.toUpperCase() !==
          filter.location.split(",")[1].trim().toUpperCase()
      )
        flag = false;
      if (filter.booking === "Booked" && !customer.booking) flag = false;
      if (filter.booking === "Not Booked" && customer.booking) flag = false;
      if (filter.plan !== "All" && customer.plan?.name !== filter.plan)
        flag = false;
      if (flag) filtered.push(customer);
    });
    return [...filtered];
  };

  // ========================================================== SEARCH CUSTOMERS
  const searchUsers = (records) => {
    return records.filter((customer) => {
      const string = `${customer.firstName} ${customer.lastName}
        ${customer.email} ${customer.phone} ${customer.drivers_license_number}`.toLowerCase();
      return string.includes(filter.search.toLowerCase());
    });
  };
  // Fetch Customers
  useEffect(() => {
    applyFilters();
  }, [filter]);

  // ========================================================== FILTER HANDLER
  const filterOptions = [
    {
      label: "Location",
      options: ["All", "Boston, MA", "Dallas, TX"],
      selected: filter.location,
    },
    {
      label: "Booking",
      options: ["All", "Booked", "Not Booked"],
      selected: filter.booking,
    },
    {
      label: "Plan",
      options: ["All", "LUXURY", "PLUSH", "POSH"],
      selected: filter.plan,
    },
  ];
  const filterHandler = (filters) => {
    const { location, booking, plan } = filters;
    navigate(
      `/customers?search=${
        filter.search ?? ""
      }&location=${location}&booking=${booking}&plan=${plan}`,
      {},
      { replace: true }
    );
    setFilter({ ...filter, location, booking, plan });
  };

  // ========================================================== DOWNLOAD CSV
  const downloadCSV = () => {
    const filtered = [];
    filteredCustomers.forEach((customer) => {
      const entry = {
        "date created": timestampToString(customer.date_created, "MM/dd/yyyy"),
        name: `${customer.firstName} ${customer.lastName}`,
        phone: customer.phone,
        email: customer.email,
        license: customer.drivers_license_front,
        address: customer.address,
        booking: customer.booking
          ? `${customer.booking.make} ${customer.booking.model} ${customer.booking.year}`
          : "-",
        "unpaid invoices": customer.unpaid_invoices ? "Yes" : "No",
      };
      filtered.push(entry);
    });
    arrayToCsv(filtered, "customers");
  };

  // Clear Search Filter
  const clearSearch = () => {
    const { location, booking, plan } = filter;
    setFilter({ ...filter, search: "" });
    navigate(
      `/customers?search=&location=${location}&booking=${booking}&plan=${plan}`,
      {},
      { replace: true }
    );
  };
  // Search handler
  const searchHandler = (value) => {
    const { location, booking, plan } = filter;
    navigate(
      `/customers?search=${value}&location=${location}&booking=${booking}&plan=${plan}`,
      {},
      { replace: true }
    );

    setFilter({ ...filter, search: value });
  };

  return (
    <div className="container grid">
      <Heading title="Customers" isBackArrow={false}>
        <FilterMenu onSubmit={filterHandler} options={filterOptions}>
          <BsFilter className="text-2xl text-white cursor-pointer hover:text-stone-400" />
        </FilterMenu>
        <IoMdPersonAdd
          onClick={(e) => navigate("/leads", e)}
          className="icon"
        />
        <MdRefresh
          onClick={fetchCustomers}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
        />
        <BiDownload
          onClick={downloadCSV}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
        />
      </Heading>

      <SearchBar
        searchHandler={searchHandler}
        clearSearch={clearSearch}
        searchValue={filter.search}
      />

      <div className="list">
        {loadingData ? (
          <LoadingSpinnerWrapper />
        ) : (
          <div className="grid gap-8">
            {filteredCustomers.map((customer) => (
              <CustomerCard customer={customer} key={customer.id} />
            ))}
          </div>
        )}
      </div>

      <div className="flex items-center justify-end space-x-4 text-sm text-stone-200">
        <p className="text-stone-400">
          Showing {filteredCustomers.length} of {filteredCustomers.length}{" "}
        </p>
        <BsArrowLeftSquare className="inline-block text-xl" />
        <BsArrowRightSquare className="inline-block text-xl" />
      </div>
    </div>
  );
}
