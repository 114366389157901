import { useState, useEffect } from "react";
export default function CheckOnline() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  if (!isOnline) return (
      <div className="absolute z-50 px-4 py-1 bg-red-600 rounded-md top-10 left-[48%]">
      <p className="text-sm text-white">Offline</p>
    </div>
  );
}
