import { useState, Fragment } from "react";
import { FiChevronDown } from "react-icons/fi";
export default function DropDownSelector({
  label,
  options,
  selected,
  setSelected,
}) {
  const [show, setShow] = useState(false);

  const select = (option) => {
    const update = [...selected, option.value];
    setSelected(update);
  };
  const deselect = (option) => {
    const update = selected.filter((item) => item !== option.value);
    setSelected(update);
  };
  const selectAll = () => {
    const update = options.map((option) => option.value);
    setSelected(update);
  };
  const deselectAll = () => {
    setSelected([]);
  };

  if (!options || options.length < 1) return null;
  return (
    <Fragment>
      {show && (
        <div
          onClick={() => setShow(false)}
          className="absolute top-0 left-0 w-screen h-screen"
        ></div>
      )}
      <div className="relative">
        <div
          onClick={() => setShow(!show)}
          className="flex justify-between py-2 text-sm border-b border-yellow-600 cursor-pointer text-stone-200 hover:opacity-75"
        >
          {label}
          <FiChevronDown className="inline ml-4" />
        </div>
        {show && (
          <div className="z-10 absolute grid gap-2 p-4 text-xs font-semibold rounded-md bg-stone-800 max-w-84 no-scrollbar max-h-[500px] overflow-scroll">
            <div className="grid w-64 grid-cols-2">
              <div
                onClick={selectAll}
                className="p-2 font-semibold text-center rounded-sm cursor-pointer text-stone-200"
              >
                Select All
              </div>
              <div
                onClick={deselectAll}
                className="p-2 font-semibold text-center rounded-sm cursor-pointer text-stone-200"
              >
                Unselect All
              </div>
            </div>
            {options.map((option) => {
              if (selected.includes(option.value))
                return (
                  <div
                    onClick={() => deselect(option)}
                    className="p-2 bg-yellow-800 rounded-md cursor-pointer hover:opacity-75 text-stone-200"
                    key={option.value}
                  >
                    <div className="flex items-center space-x-6">
                    {option.image && <img src={option.image} className="inline h-12 w-18" />}
                      <p>{option.label}</p>
                    </div>
                  </div>
                );
              else
                return (
                  <div
                    onClick={() => select(option)}
                    className="p-2 rounded-md cursor-pointer hover:opacity-75 text-stone-200 hover:bg-stone-600"
                    key={option.value}
                  >
                    <div className="flex items-center space-x-6">
                      {option.image && <img src={option.image} className="inline h-12 w-18" />}
                      <p>{option.label}</p>
                    </div>
                  </div>
                );
            })}
          </div>
        )}
      </div>
    </Fragment>
  );
}
