import { useContext } from "react";
import { UtilityContext } from "../../context/util-context";

export default function PartnerCard({ partner }) {
  const { navigate } = useContext(UtilityContext);
  return (
    <div
      onClick={(e) => navigate("/partners/info?id=" + partner.id, e)}
      className="grid text-sm pill md:grid-cols-3 justify-items-center text-stone-200"
    >
      <p className="">{partner.name}</p>
      <p className="">{partner.email}</p>
      <p className="">{partner.phone}</p>
    </div>
  );
}
