import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase";

export default function uploadFile(path, file, setUploading) {
  if (path && file) {
    return new Promise((resolve, reject) => {
      try {
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.ceil(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setUploading && setUploading(progress);
          },
          (error) => {
            console.log(error);
            reject("");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              resolve(url);
            });
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  } else return "";
}
