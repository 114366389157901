import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import { DataContext } from "../../context/data-context";
import { useSearchParams } from "react-router-dom";
import { editLead, fetchLeads } from "../../api/fetch";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import { timestampToString } from "../../util/timestamp-to-date";
import LoadingSpinner from "../UI/LoadingSpinner";
import SelectPair from "../UI/SelectPair";
import { FaEdit } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { sendQuote } from "../../api/add";

export default function LeadInfo() {
  const { user } = useContext(AuthContext);
  const { vehicles } = useContext(DataContext);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState();
  const [edit, setEdit] = useState(false);
  const [lead, setLead] = useState();
  const [showQuote, setShowQuote] = useState(false);
  const [numVehicles, setNumVehicles] = useState(0);

  // ========================================================== FETCH LEAD
  const fetchLeadHandler = async () => {
    try {
      setLoading(true);
      setWarning();
      const res = await fetchLeads(user, { id: id });
      setLead(res);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setWarning(err.message);
    }
  };

  // ========================================================== EDIT LEAD
  const editLeadHandler = async () => {
    try {
      setLoading(true);
      setWarning();
      const payload = {
        id: id,
        status: document.getElementById("Status").value,
        notes: document.getElementById("Notes").value,
      };
      await editLead(user, payload);
      fetchLeadHandler();
      setEdit(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setWarning(err.message);
    }
  };

  // ========================================================== SEND QUOTE
  const sendQuoteHandler = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setWarning();
      if (numVehicles < 1) throw new Error("Please add at least one vehicle.");

      // Create Payload
      const payload = {
        leadId: lead.id,
        phone:
          document.getElementById("Lead Phone").value || lead.phone || false,
        email:
          document.getElementById("Lead Email").value || lead.email || false,
        quote: {
          name: lead.name,
          subscription: document.getElementById("Subscription Cost").value,
          months: document.getElementById("Subscription Months").value,
          trial: document.getElementById("Trial").value,
          phone: document.getElementById("Sales Advisor Phone").value,
        },
      };

      // Add Vehicles to Payload
      const quoteVehicles = [];
      Array.from({ length: numVehicles }).forEach((_, idx) => {
        console.log(document.getElementById(`vehicle${idx}`).value);
        const vehicle = vehicles.filter(
          (v) => v.id === document.getElementById(`vehicle${idx}`).value
        )[0];
        if (!vehicle) throw new Error("Vehicle not found.");
        else console.log(vehicle);
        quoteVehicles.push({
          id: vehicle.id,
          image: vehicle.images.corner,
          make: vehicle.make,
          model: vehicle.model,
          year: vehicle.year,
          monthly: document.getElementById(`Monthly Rate ${idx}`).value,
          insurance: document.getElementById(`insurance${idx}`).checked,
          maintenance: document.getElementById(`maintenance${idx}`).checked,
          swap: document.getElementById(`swaps${idx}`).checked,
        });
      });
      payload.quote.vehicles = quoteVehicles;

      // Send Quote
      await sendQuote(user, payload);
      setShowQuote(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setWarning(err.message);
    }
  };

  useEffect(() => {
    fetchLeadHandler();
  }, []);

  return (
    <div className="container">
      <Heading title="Lead Information">
        {edit ? (
          <Fragment>
            <FiCheck className="icon" onClick={editLeadHandler} />
            <AiOutlineClose onClick={() => setEdit(false)} className="icon" />
          </Fragment>
        ) : (
          <Fragment>
            <FaEdit className="icon" onClick={() => setEdit(true)} />
          </Fragment>
        )}
      </Heading>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="info">
          <DetailPair
            label="Date Created"
            value={timestampToString(lead?.date_created, "MM/dd/yyyy")}
          />
          <DetailPair label="Name" value={lead?.name} />
          <DetailPair label="Phone" value={lead?.phone} />
          <DetailPair label="Budget" value={lead?.budget} />
          <DetailPair label="Mileage" value={lead?.mileage} />
          <DetailPair
            label="Brands"
            value={lead?.brands.reduce((a, b) => a + ", " + b)}
          />
          <SelectPair
            options={["Called", "Closed"]}
            label="Status"
            value={lead?.status}
            edit={edit}
          />
          <div className="md:col-span-2">
            <DetailPair label="Notes" value={lead?.notes} edit={edit} />
          </div>

          {!showQuote && (
            <div className="md:col-span-3">
              <Heading
                isBackArrow={false}
                title="Quotes"
                text="Following are the quotes already sent to this customer. 
                You can re-share them with customer opening the quote and copying the link."
              />
              {lead.quotes?.map((quote, idx) => (
                <div
                  onClick={() =>
                    window.open(
                      `https://poshcars.io/quote?id=${lead.id}-${idx}`,
                      "_blank"
                    )
                  }
                  key={idx}
                  className="grid grid-cols-5 mt-4 pill"
                >
                  <p>{timestampToString(quote.date_created, "MM/dd/yyy")}</p>
                  <p>
                    ${quote.subscription} for {quote.months} months
                  </p>
                  <p className="md:col-span-3">
                    {quote.vehicles.map((v, idx) => {
                      if (idx < 3)
                        return `${v.make} ${v.model} ${v.year} at $${v.monthly}/mo, `;
                    })}
                    {quote.vehicles.length > 2 && "..."}
                  </p>
                </div>
              ))}
            </div>
          )}

          {showQuote && (
            <div className="md:col-span-3">
              <Heading
                isBackArrow={false}
                title="New Quote"
                text="Use this form to send the lead a quote. The quote is send via SMS as a clickable link and as an email. Provide a contact number where you can be reached at by the lead."
              >
                <AiOutlineClose
                  onClick={() => setShowQuote(false)}
                  className="icon"
                />
              </Heading>
              <form onSubmit={sendQuoteHandler} className="info">
                <DetailPair
                  label="Sales Advisor Phone"
                  edit={true}
                  required={true}
                />
                <DetailPair
                  label="Lead Email"
                  edit={true}
                  placeholder={lead.email}
                />
                <DetailPair
                  label="Lead Phone"
                  edit={true}
                  type="number"
                  placeholder={lead.phone}
                />
                <DetailPair
                  label="Subscription Cost"
                  edit={true}
                  type="number"
                  required={true}
                />
                <DetailPair
                  label="Subscription Months"
                  edit={true}
                  type="number"
                  required={true}
                />
                <SelectPair
                  options={[
                    { name: "No", id: false },
                    { name: "Yes", id: true },
                  ]}
                  label="Trial"
                  edit={true}
                  required={true}
                />
                {Array.from({ length: numVehicles }).map((_, idx) => (
                  <div
                    key={idx}
                    className="grid gap-6 md:grid-cols-3 md:col-span-3"
                  >
                    <div className="grid text-stone-400">
                      <label className="text-sm">Vehicle:</label>
                      <select
                        className="form-input"
                        id={`vehicle${idx}`}
                        required
                      >
                        {vehicles.map((vehicle) => (
                          <option value={vehicle.id} key={vehicle.id}>
                            {vehicle.model} {vehicle.year} {vehicle.color}{" "}
                            {vehicle.license}
                          </option>
                        ))}
                      </select>
                    </div>
                    <DetailPair
                      label={`Monthly Rate ${idx}`}
                      edit={true}
                      required={true}
                    />
                    <div className="flex items-center space-x-2 text-sm text-stone-400">
                      <label>Insurance</label>
                      <input
                        type="checkbox"
                        className="checkbox"
                        id={`insurance${idx}`}
                        defaultChecked={true}
                      />
                      <label>Maintenance</label>
                      <input
                        type="checkbox"
                        className="checkbox"
                        id={`maintenance${idx}`}
                        defaultChecked={true}
                      />
                      <label>Swaps</label>
                      <input
                        type="checkbox"
                        className="checkbox"
                        id={`swaps${idx}`}
                        defaultChecked={true}
                      />
                    </div>
                  </div>
                ))}
                <div
                  onClick={() => setNumVehicles(numVehicles + 1)}
                  className="self-end btn-secondary"
                >
                  Add Vehicle
                </div>
                {numVehicles > 0 && (
                  <div
                    onClick={() => setNumVehicles(numVehicles - 1)}
                    className="self-end btn-secondary"
                  >
                    Remove Vehicle
                  </div>
                )}
                {numVehicles > 0 && (
                  <button type="submit" className="self-end btn-primary">
                    Send Quote
                  </button>
                )}
              </form>
            </div>
          )}
          {!showQuote && (
            <div onClick={() => setShowQuote(true)} className="btn-secondary">
              Create Quote
            </div>
          )}
        </div>
      )}
      {warning && <p className="warning">{warning}</p>}
    </div>
  );
}
