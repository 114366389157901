import { auth } from "../firebase";

/* ========================= POSH ADMIN API WRAPPER =========================
This function serves as a wrapper for all requests to the POSH Admin API 
endpoints. It takes the endpoint and the payload as arguments, 
checks whether the response is successful or not and returns accordingly.

    @param {string} path - The endpoint of the API
    @param {object} payload - The data to be sent to the API
    @returns {object} - The response from the API
    @throws {Error} - If the response is not successful
 */
export const requestAdminAPI = async (path, payload) => {
  // Get authenticated user from firebase auth
  const user = auth.currentUser;
  const idToken = await user.getIdToken();

  // Make a request to the API
  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/admin/${path}`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_ADMIN_AUTH_TOKEN}`,
        adminId: `${user.uid}`,
        idToken: `${idToken}`,
        "Content-Type": "application/json",
      },
      body: payload ? JSON.stringify(payload) : null,
    }
  );

  // Handle response
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};

/* ========================= POSH PUBLIC API WRAPPER =========================
This function serves as a wrapper for all requests to the POSH Public API 
endpoints. It takes the endpoint and the payload as arguments, 
checks whether the response is successful or not and returns accordingly.

    @param {string} path - The endpoint of the API
    @param {object} payload - The data to be sent to the API
    @returns {object} - The response from the API
    @throws {Error} - If the response is not successful
 */
export const requestPublicAPI = async (path, payload) => {
  // Make a request to the API
  const response = await fetch(
    `${process.env.REACT_APP_ADMIN_API_URL}/public/${path}`,
    {
      method: "POST",
      headers: {
        Authorization: `${process.env.REACT_APP_APP_AUTH_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: payload ? JSON.stringify(payload) : null,
    }
  );

  // Handle response
  if (response.status === 200) {
    return await response.json();
  } else {
    const error = await response.text();
    throw new Error(error);
  }
};
