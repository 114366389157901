// External Imports
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";

// Internal Imports
import { timestampToString } from "../../util/timestamp-to-date";
import Heading from "./Heading";
import { truncate } from "../../util/format-text";

/** ========================================================================
 * * Note Card Component
 * This component is used to display a single note card.
 * ========================================================================== */

export default function NoteCard({ note, collection, id }) {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-md mx-4 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">
                  <div
                    className="absolute right-4 top-4"
                    onClick={() => setOpen(false)}
                  >
                    <AiOutlineClose className="w-6 h-6 cursor-pointer text-stone-200" />
                  </div>
                  <div className="container">
                    <p className="text-stone-400">{timestampToString(note.date_created, "MM/dd/yyyy hh:mm a")}</p>
                    <p className="font-semibold">{note.note}</p>
                    <p className="text-stone-400">{note.author}</p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div onClick={() => setOpen(true)} className="grid md:grid-cols-4 pill">
        <p className="text-stone-400">
          {timestampToString(note.date_created, "MM/dd/yyyy hh:mm a")}
        </p>
        <p className="md:col-span-2">{truncate(note.note, 50)}</p>
        <p className="text-stone-400">{note.author}</p>
      </div>
    </Fragment>
  );
}
