// External Imports
import { Fragment, useContext } from "react";

// Internal Imports
import { UtilityContext } from "../../context/util-context";
import { MdArrowBack } from "react-icons/md";

/** ========================================================================
 * * Page Heading Component
 * This component is used to display a page heading with optional text.
 * ========================================================================== */

export default function Heading({ title, text, children, isBackArrow = true }) {
  const { goBack } = useContext(UtilityContext);

  return (
    <div className="grid gap-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          {isBackArrow && <MdArrowBack onClick={goBack} className="icon" />}
          <p className="text-lg font-semibold text-white">{title}</p>
        </div>
        <div className="flex items-center space-x-6">{children}</div>
      </div>
      {text && (
        <Fragment>
          <hr className="hr" />
          <p className="text-xs text-stone-400">{text}</p>
        </Fragment>
      )}
    </div>
  );
}
