// External Imports
import { useContext, useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useSearchParams } from "react-router-dom";

// Internal Imports
import { requestAdminAPI } from "../../api/posh-api";
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { timestampToString } from "../../util/timestamp-to-date";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import PaymentForm from "../UI/PaymentForm";
import ChargingCard from "./ChargingCard";

/** ========================================================================
 * * Booking Charging Page
 * Renders a page, which displays EV charging consumed during a booking.
 * ========================================================================== */

export default function BookingCharging() {
  // Load Context
  const { customers, bookings } = useContext(DataContext);
  const { goBack } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [charging, setCharging] = useState([]);
  const [total, setTotal] = useState(0);

  // Get Booking and Customer from URL
  const [searchParams] = useSearchParams();
  const bookingId = searchParams.get("bookingId");
  const booking = bookings.filter((booking) => booking.id === bookingId)[0];
  const customerId = searchParams.get("customerId");
  const customer = customers.filter(
    (customer) => customer.id === customerId
  )[0];

  /* ======================= FETCH BOOKING CHARGING ======================= */
  const fetchBookingChargingHandler = async () => {
    try {
      setLoading(true);
      if (!bookingId) return;
      // Send API Request
      const response = await requestAdminAPI("fetch-booking-charging", {
        bookingId,
      });
      selectAllHandler(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  /* ======================= TOTAL SELECTED RECORDS ======================= */
  const calculateTotal = (charging) => {
    const selectedCharging = charging.filter((charge) => charge.selected);
    let total = 0;
    selectedCharging.forEach((charge) => {
      total += Number(charge["Total Inc. VAT"]);
    });
    setTotal(total.toFixed(2));
  };

  /* ======================= SELECT CHARGING RECORD ======================= */
  const selectHandler = (charge) => {
    charge.selected = !charge.selected;
    setCharging([...charging]);
    calculateTotal(charging);
  };

  /* ======================= SELECT ALL CHARGING RECORDS ======================= */
  const selectAllHandler = (charging) => {
    charging.forEach((charge) => {
      charge.selected = true;
    });
    setCharging([...charging]);
    calculateTotal(charging);
  };

  /* ======================= UNSELECT ALL CHARGING RECORDS ======================= */
  const unselectAllHandler = () => {
    charging.forEach((charge) => {
      charge.selected = false;
    });
    setCharging([...charging]);
    calculateTotal(charging);
  };

  // Fetch Booking Charging on load
  useEffect(() => {
    fetchBookingChargingHandler();
  }, []);

  if (!bookingId)
    return (
      <div className="container">
        <p className="text-white">Booking ID not provided!</p>
      </div>
    );

  if (warning)
    return (
      <div className="container">
        <MdArrowBack onClick={goBack} className="icon" />
        <p className="text-red-800">{warning}</p>
      </div>
    );

  if (loading)
    return (
      <div className="container">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div className="container">
      <div className="grid gap-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <MdArrowBack onClick={goBack} className="icon" />
            <p className="hidden text-lg font-semibold text-white md:block ml-4">
              Booking EV Charging
            </p>
          </div>
          <div className="flex items-center space-x-6">
            <p
              onClick={() => selectAllHandler(charging)}
              className="text-sm cursor-pointer hover:text-stone-400"
            >
              Select All
            </p>
            <p
              onClick={unselectAllHandler}
              className="text-sm cursor-pointer hover:text-stone-400"
            >
              Unselect All
            </p>
          </div>
        </div>
        <div className="max-h-[422px] overflow-scroll">
          <div className="grid gap-4">
            {charging.map((charge, index) => (
              <ChargingCard
                key={index}
                charging={charge}
                selected={charge.selected}
                onClick={() => selectHandler(charge)}
              />
            ))}
            {charging?.length === 0 && (
              <p className="text-center text-stone-400">No charging found!</p>
            )}
          </div>
        </div>
        <div className="flex space-x-4 text-lg">
          <p className="font-semibold">Total: </p>
          <p className="font-semibold">${total}</p>
          <p className="text-red-800">{warning}</p>
        </div>
        <PaymentForm
          amount={(Number(total) * 1.039).toFixed(2)}
          selectedCustomer={customer}
          description={`EV Charging for ${booking?.vehicle?.make} ${
            booking?.vehicle?.model
          } (${timestampToString(
            booking.start_date,
            "MM/dd/yyyy"
          )} - ${timestampToString(booking.end_date, "MM/dd/yyyy")})`}
          bookingId={bookingId}
        />
      </div>
    </div>
  );
}
