import { useState, useContext } from "react";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import { DataContext } from "../../context/data-context";
import TollCard from "./TollCard";
import Heading from "../UI/Heading";
import { arrayToCsv } from "../../util/arrayToCsv";
import { BiDownload } from "react-icons/bi";
import { AuthContext } from "../../context/auth-context";
import { fetchTolls } from "../../api/fetch";
import DropDownSearch from "../UI/DropDownSearch";

export default function Tolls() {
  const { vehicles } = useContext(DataContext);
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [values, setValues] = useState({});
  const [tolls, setTolls] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState("");
  // ================== Handlers ==================
  const searchTollsHandler = async (e) => {
    try {
      e.preventDefault();
      setWarning(false);
      setLoading(true);
      const vehicleId = e.target.vehicle.value;
      const start_date = e.target.from.value;
      const end_date = e.target.to.value;
      setValues({ vehicleId, start_date, end_date });
      const response = await fetchTolls(user, {
        vehicleId,
        start_date,
        end_date,
      });
      setTolls(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  const getTotalAmount = (tolls) => {
    if (!tolls) return "0.00";
    let total = 0;
    tolls.forEach((toll) => {
      total += Number(toll.amount);
    });
    return total.toFixed(2);
  };

  const downloadCSV = () => {
    if (!tolls) return;
    arrayToCsv(tolls, "tolls");
  };

  const searchBar = (
    <form
      onSubmit={searchTollsHandler}
      className="grid items-end gap-4 p-4 h-fit md:grid-cols-5 bg-stone-800 rounded-xl"
    >
      <DropDownSearch
        title="VEHICLE"
        options={vehicles}
        selectHandler={(value) => setSelectedVehicle(value)}
        nameFormatter={(v) => `${v.make} ${v.model} ${v.year} ${v.license}`}
        selectedValue={selectedVehicle}
      />
      <div className="grid gap-2">
        <label className="text-sm">From:</label>
        <input
          type="date"
          className="form-input"
          name="from"
          required
          defaultValue={values.start_date}
        />
      </div>
      <div className="grid gap-2">
        <label className="text-sm">To:</label>
        <input
          type="date"
          className="form-input"
          name="to"
          required
          defaultValue={values.end_date}
        />
      </div>
      <button type="submit" className="btn-primary">
        Search
      </button>
    </form>
  );

  if (loading)
    return (
      <div className="w-full h-screen p-8 overflow-scroll rounded-xl bg-stone-900">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div className="container grid">
      <Heading title="Tolls" isBackArrow={false}>
        <BiDownload
          onClick={downloadCSV}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
          title="Download CSV"
        />
      </Heading>

      {searchBar}

      <div className="list">
        {warning && <p className="warning">{warning}</p>}
        <div className="grid items-start gap-4">
          {tolls &&
            tolls.map((toll, index) => <TollCard toll={toll} key={index} />)}
          {tolls && tolls?.length === 0 && (
            <p className="text-center text-stone-400">No tolls found!</p>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between space-x-2 text-sm text-stone-400">
        <p>Total Amount: ${getTotalAmount(tolls)}</p>
        <p>Total Entries: {tolls?.length || "0"}</p>
      </div>
    </div>
  );
}
