import format from "date-fns/format";
import { truncate } from "../../util/format-text";

/** ========================================================================
 * * Charging Card Component
 * Renders a component, which summarizes EV charging details.
 * ========================================================================== */

export default function ChargingCard({ charging, selected, onClick }) {
  return (
    <div onClick={onClick && onClick} className={`grid items-center md:grid-cols-5 gap-x-6 ${selected ? "selected-card" : "card"}`}>
      <div className="flex items-center space-x-4">
        <p className="font-semibold text-stone-200">
          {charging.vehicle?.make} {charging.vehicle?.model}{" "}
          {charging.vehicle?.license}
        </p>
      </div>
      <div className="text-center">
        <p className="text-stone-200">
          {format(
            new Date(charging["ChargeStartDateTime"]),
            "MMM do, yyyy h:mm a"
          )}
        </p>
      </div>
      <div className="text-center">
        <p className="text-stone-200">
          {truncate(charging["Description"].split(" ")[0], 20)}
        </p>
      </div>
      <div className="text-center">
        <p className="text-stone-200">
          {truncate(charging["SiteLocationName"], 20)}
        </p>
      </div>
      <div className="text-center">
        <p className="text-stone-200">${charging["Total Inc. VAT"]}</p>
      </div>
    </div>
  );
}
