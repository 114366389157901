// External Imports
import { useContext } from "react";

// Internal Imports
import { UtilityContext } from "../../context/util-context";
import TierLabel from "../UI/TierLabel";

/** ========================================================================
 * * Customer Card Component
  * Renders a card displaying customer information.
 * ========================================================================== */

export default function CustomerCard({ customer }) {
  const { navigate } = useContext(UtilityContext);

  return (
    <div
      onClick={(e) => navigate(`/customers/info?id=${customer.id}`, e)}
      className="grid items-center gap-4 p-4 text-sm border rounded-lg cursor-pointer md:grid-cols-4 hover:bg-stone-800 justify-items-center border-stone-400 bg-stone-900 gap-x-6"
    >
      <div className="flex items-center space-x-4">
        <p className="font-semibold text-stone-200">
          {customer.firstName} {customer.lastName}
        </p>
      </div>
      <div className="text-center">
        <p className="text-stone-200">{customer.phone || "-"}</p>
        <p className="text-stone-200">{customer.email}</p>
      </div>
      <div className="text-center">
        <p className="text-stone-200">
          {customer.booking
            ? `${customer.booking.make} ${customer.booking.model}  ${customer.booking.year}`
            : "-"}
        </p>
        <p className="text-stone-200">
          {customer.booking ? `${customer.booking.license}` : "-"}
        </p>
      </div>
      <div className="hidden text-center md:block">
        <p className={`font-Play text-white`}>
          {customer.plan ? <TierLabel tier={customer.plan.name} /> : "-"}
        </p>
        <p className="text-stone-400">
          {customer.plan ? (customer.plan.active ? "active" : "inactive") : "-"}
        </p>
      </div>
    </div>
  );
}
