import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function BarGraph({ data, expenses = false }) {
  return (
    <div className="w-full my-4 md:my-8 h-52 md:h-72">
      <ResponsiveContainer width="100%" height="100%">
        {!data.length ? (
          <div className="flex items-center justify-center h-full">
            <p>No data available</p>
          </div>
        ) : (
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis tick={{ fontSize: 12 }} />
            <Tooltip wrapperStyle={{ color: "#000" }} />
            {expenses && <Bar dataKey="expenses" stackId="a" fill="#991b1b" />}
            <Bar dataKey="revenue" stackId="b" fill="#ca8a04" />
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
}
