import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";

export default function PaginationMenu({ showing, total }) {
  return (
    <div className="flex items-center justify-end ml-auto space-x-4 text-sm text-stone-200">
      <p className="text-stone-400">
        Showing {showing} of {total}
      </p>
      <BsArrowLeftSquare className="inline-block text-xl" />
      <BsArrowRightSquare className="inline-block text-xl" />
    </div>
  );
}
