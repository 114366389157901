// External Imports
import { useState, useContext } from "react";
import { BsPlusLg } from "react-icons/bs";
import { BiDownload } from "react-icons/bi";
import { MdRefresh } from "react-icons/md";

// Internal Imports
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import { arrayToCsv } from "../../util/arrayToCsv";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import ChargingCard from "./ChargingCard";
import Heading from "../UI/Heading";

/** ========================================================================
 * * EV Charging Page
 * Renders a page, which displays all EV charging records.
 * ========================================================================== */

export default function Charging() {
  // Load Context
  const { vehicles, charging, fetchCharging } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [filteredCharging, setFilteredCharging] = useState();

  /* ======================= FILTER RECORDS ======================= */
  const filterChargingHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    const { vehicle, from, to } = e.target;
    const vehicleId = vehicle.value;
    const startDate = new Date(from.value);
    const endDate = new Date(to.value);

    startDate.setHours(0, 0, 0, 0); //setting hour, minute, setting to start of day
    endDate.setHours(23, 59, 59, 999); //setting hour, minute, setting to end of day

    const filteredCharging = charging.filter(
      ({ ChargeStartDateTime, vehicle }) => {
        const chargeDate = new Date(ChargeStartDateTime);

        return (
          chargeDate >= startDate &&
          chargeDate <= endDate &&
          (vehicleId === "All" || vehicle.id.includes(vehicleId))
        );
      }
    );

    setFilteredCharging(filteredCharging);
    setLoading(false);
  };

  /* ======================= TOTAL FILTERED RECORDS ======================= */
  const getTotalAmount = (data) => {
    let total = 0;
    data.forEach((charge) => {
      total += Number(charge["Total Inc. VAT"]);
    });
    return total.toFixed(2);
  };

  /* ======================= DOWNLOAD CSV ======================= */
  const downloadCSV = () => {
    if (!filteredCharging) return;
    filteredCharging.forEach((charge) => {
      charge["Name"] = "customer";
    });
    arrayToCsv(filteredCharging, "ev-charging");
  };

  // Customer search bar for filtering records
  const searchBar = (
    <form
      onSubmit={filterChargingHandler}
      className="grid items-end gap-4 p-4 h-fit md:grid-cols-5 bg-stone-800 rounded-xl"
    >
      <div className="grid gap-2 md:col-span-2">
        <label className="text-sm">Vehicle:</label>
        <select className="select" name="vehicle">
          <option value="All">All</option>
          {vehicles.map((vehicle) => {
            if (vehicle.fuel === "Electric")
              return (
                <option value={vehicle.id} key={vehicle.id}>
                  {vehicle.make +
                    " " +
                    vehicle.model +
                    " " +
                    vehicle.color +
                    " " +
                    vehicle.license}
                </option>
              );
          })}
        </select>
      </div>
      <div className="grid gap-2">
        <label className="text-sm">From:</label>
        <input type="date" className="form-input" name="from" required />
      </div>
      <div className="grid gap-2">
        <label className="text-sm">To:</label>
        <input type="date" className="form-input" name="to" required />
      </div>
      <button type="submit" className="btn-primary">
        Search
      </button>
    </form>
  );

  if (loading)
    return (
      <div className="container">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div className="container grid">
      <Heading title="EV Charging" isBackArrow={false}>
        <BiDownload
          onClick={downloadCSV}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
          title="Download CSV"
        />
        <BsPlusLg
          onClick={(e) => navigate("/charging/add", e)}
          className="text-lg text-white cursor-pointer hover:text-stone-400"
          title="Add Payment"
        />
        <MdRefresh
          onClick={fetchCharging}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
          title="Refresh"
        />
      </Heading>

      {searchBar}

      <div className="list">
        <div className="grid items-start gap-4">
          {filteredCharging &&
            filteredCharging.map((charge) => (
              <ChargingCard key={charge.id} charging={charge} />
            ))}
          {filteredCharging && filteredCharging.length === 0 && (
            <p className="text-center text-stone-400">No records found!</p>
          )}
        </div>
      </div>

      <div className="flex items-center justify-end space-x-2">
        {filteredCharging?.length > 0 ? (
          <p className="text-stone-400">
            Total: ${getTotalAmount(filteredCharging)}
          </p>
        ) : (
          <p className="text-stone-400">
            Total: ${getTotalAmount(charging)} - {charging.length} records
          </p>
        )}
      </div>
    </div>
  );
}
