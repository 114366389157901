import { useContext, useState } from "react";
import DetailPair from "../UI/DetailPair";
import Heading from "../UI/Heading";
import LinkPair from "../UI/LinkPair";
import { DataContext } from "../../context/data-context";
import { MdRefresh } from "react-icons/md";
import { toTitleCase } from "../../util/toTitleCase";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import { uploadImageToFirebase } from "../../util/upload-image-to-firebase";
import { addBrand } from "../../api/add";
import { AuthContext } from "../../context/auth-context";

export default function Brands() {
  const { user } = useContext(AuthContext);
  const { brands, fetchBrands } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);

  const addBrandHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const name = event.target["Make"].value;
      const file = event.target["Logo"].files[0];

      const path = `Brands/${name}`;
      const url = await uploadImageToFirebase(path, "logo", file, () => {});

      const response = await addBrand(user, { name: name, image: url });
      fetchBrands(() => {
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setWarning(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="container grid">
      <Heading
        title="Brands"
        text="Before adding a vehicle, make sure that the brand of the vehicle is added to this list. It allows the system to capture brand logos."
      >
        <MdRefresh className="text-2xl icon" onClick={() => fetchBrands()} />
      </Heading>

      <div className="grid md:grid-cols-4 gap-8 overflow-scroll max-h-[600px] no-scrollbar mb-8">
        {brands.map((brand, idx) => (
          <div key={idx} className="grid items-center grid-cols-2 text-sm pill">
            <img className="h-8 ml-auto" src={brand.image} />
            <p className="text-stone-200">{toTitleCase(brand.id)}</p>
          </div>
        ))}
      </div>

      <Heading
        title="Add Brand"
        text="Incorrect spellings will cause vehicle make logos to not show and other brand related issues."
      />

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <form onSubmit={addBrandHandler} className="items-end info">
          <DetailPair
            label="Make"
            type="text"
            placeholder="Brand Name"
            required={true}
            edit={true}
          />
          <LinkPair label="Logo" type="file" edit={true} required={true} />
          <button type="submit" className="btn-primary">
            Add Brand
          </button>
          {warning && <p className="text-sm text-red-700">{warning}</p>}
        </form>
      )}
    </div>
  );
}
