import Heading from "../UI/Heading";
import SearchBar from "../UI/SearchBar";
import { MdRefresh } from "react-icons/md";
import { useContext, useState } from "react";
import { DataContext } from "../../context/data-context";
import InvoiceCard from "./InvoiceCard";

export default function Invoices() {
  const { invoices, fetchInvoices } = useContext(DataContext);
  const [filteredInvoices, setFilteredInvoices] = useState(invoices);

  const searchHandler = (query) => {
    const filtered = invoices.filter((invoice) => {
      const invoiceInfo = `${invoice.id} ${invoice.customer.firstName} ${invoice.customer.lastName} ${invoice.customer.email} ${invoice.customer.phone} ${invoice.description}`;
      return invoiceInfo.toLowerCase().includes(query.toLowerCase());
    });
    setFilteredInvoices(filtered);
  };

  const clearSearchHandler = () => {
    setFilteredInvoices(invoices);
  };

  const refreshHandler = () => {
    fetchInvoices();
  };

  return (
    <div className="container grid content-start gap-4">
      <Heading title="Invoices">
        <MdRefresh
          onClick={refreshHandler}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
        />
      </Heading>
      <SearchBar
        searchHandler={searchHandler}
        clearSearch={clearSearchHandler}
      />

      <div className="list">
        {filteredInvoices.map((invoice) => (
          <InvoiceCard invoice={invoice} />
        ))}
      </div>
    </div>
  );
}
