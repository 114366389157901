import { AiOutlineClose } from "react-icons/ai";
import DetailPair from "../UI/DetailPair";
import Heading from "../UI/Heading";
import LinkPair from "../UI/LinkPair";
import SelectPair from "../UI/SelectPair";

export default function CreditApplication({ partner, setOpen }) {
  return (
    <div className="container overflow-scroll">
      <Heading
        title="Credit Application"
        text="The following information is collected from the partner dashboard under Settings/Credit Application."
        isBackArrow={false}
      >
        <AiOutlineClose onClick={() => setOpen(false)} className="icon" />
      </Heading>
      <div className="info">
        <DetailPair label="Name" value={partner?.name} />
        <DetailPair label="Address" value={partner.address} />
        <DetailPair
          label="Driver's License Number"
          value={partner.drivers_license_number}
        />
        <DetailPair label="Phone" value={partner.phone} />
        <DetailPair label="Social Security Number" value={partner.ssn} />
        <DetailPair label="Date of Birth" value={partner.dob} type="date" />

        <div className="md:col-span-3">
          <Heading title="Residence" isBackArrow={false} />
        </div>

        <SelectPair
          label="Residence Type"
          value={partner.residence_type}
          options={["Own", "Rent", "Other"]}
        />
        <DetailPair
          label="Monthly Rent/Mortgage"
          value={partner.monthly_rent}
        />
        <DetailPair
          label="Years at Residence"
          value={partner.years_at_residence}
          type="number"
        />
        <DetailPair
          label="Months at Residence"
          value={partner.months_at_residence}
          type="number"
        />

        <div className="md:col-span-3">
          <Heading title="Employment" isBackArrow={false} />
        </div>

        <DetailPair label="Employer" value={partner.employer} />
        <DetailPair label="Occupation" value={partner.occupation} />
        <DetailPair
          label="Years at Current Employment"
          value={partner.employment_years}
        />
        <DetailPair
          label="Monthly Salary"
          value={partner.monthly_salary}
          type="number"
        />
        <DetailPair
          label="Other Income"
          value={partner.other_income}
          type="number"
        />

        <div className="md:col-span-3">
          <Heading title="Documents" isBackArrow={false} />
        </div>

        <LinkPair
          label={`Tax Returns ${Number(new Date().getFullYear()) - 1}`}
          link={partner.tax_returns_1}
        />
        <LinkPair
          label={`Tax Returns ${Number(new Date().getFullYear()) - 2}`}
          link={partner.tax_returns_2}
        />
        <LinkPair label="Picture of SSN" link={partner.ssn_front} />
        <LinkPair
          label="Proof of Residence"
          link={partner.proof_of_residence}
        />
        <LinkPair label="Pay Stub (YTD)" link={partner.pay_stubs} />
        <LinkPair
          label="Bank Statements (last 3 months)"
          link={partner.bank_statements}
        />
        <LinkPair label="Selfie with ID" link={partner.selfie_id} />
        <LinkPair
          label="Credit App Agreement"
          link={partner.credit_app_agreement}
        />
      </div>
    </div>
  );
}
