// External Packages
import { useContext } from "react";

// Internal Packages
import { AuthContext } from "../../context/auth-context";

// Internal Components
import PoshLogo from "../UI/PoshLogo";
import CheckOnline from "../UI/CheckOnline";

// External Components
import { Popover } from "@headlessui/react";
import { BiLogOut } from "react-icons/bi";

export default function Navbar() {
  const { user, logout } = useContext(AuthContext);

  return (
    <header className="z-20 mt-2 mb-4">
      <CheckOnline />
      <Popover className="relative mx-4 transition-all duration-200 shadow-lg bg-stone-900 rounded-2xl">
        <div className="mx-6 md:mx-12">
          <div className="flex items-center justify-between py-6">
            <div className="flex items-center justify-start space-x-4 lg:w-0 lg:flex-1">
              <PoshLogo />
              <p className="text-3xl font-extralight text-stone-400">Admin</p>
            </div>
            <div className="-my-2 md:hidden">
              <div className="p-2 rounded-lg bg-primary">
                <BiLogOut className="text-xl text-white" onClick={logout}/>
              </div>
            </div>

            <div className="items-center justify-end hidden md:flex md:flex-1 lg:w-0">
              {user && (
                <div className="flex items-center space-x-4">
                  <p className="text-sm font-semibold text-stone-200">
                    {user.displayName}
                  </p>
                  <div onClick={() => logout()} className="btn-secondary">
                    Log out
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Popover>
    </header>
  );
}
