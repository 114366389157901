import { NavLink } from "react-router-dom";

export default function MenuItem({ setSelected, title }) {
  return (
    <NavLink to={`/${title.toLowerCase()}`}>
      <div
        onClick={() => setSelected(title)}
        className="p-3 m-4 text-center rounded-md cursor-pointer bg-stone-600 hover:bg-stone-700"
      >
        <p className="font-normal text-white">{title}</p>
      </div>
    </NavLink>
  );
}
