// External Imports
import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FiCheck } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { MdRefresh } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

// Internal Imports
import { requestAdminAPI, requestPublicAPI } from "../../api/posh-api";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import Switch from "../UI/Switch";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import SelectPair from "../UI/SelectPair";

/** ========================================================================
 * * Subscription Page
 * Renders a page, which displays details of a specific customer's subscription.
 * TODO: Complete add subscription functionality.
 * ========================================================================== */

export default function AddSubscription() {
  // Get Customer ID from URL
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  // Initialize States
  const [customer, setCustomer] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState("");

  /* ======================= INPUT CHANGE HANDLER ======================= */
  const onInputChange = (e, desc) => {
    if (desc) {
      setDescription(desc);
    };
    const plan = document.getElementById("Plan").value;
    setDescription(
      `${plan.toUpperCase()} Subscription Plan set up fee.`
    );
  };

  /* ======================= FETCH CUSTOMER ======================= */
  const fetchCustomer = async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("fetch-customer", {
        customerId: id,
      });
      setCustomer(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setWarning(err.message);
    }
  };

  /* ======================= FETCH SUBSCRIPTION PLANS ======================= */
  const fetchPlans = async () => {
    try {
      setLoading(true);
      const response = await requestPublicAPI("fetch-subscription-plans");
      setPlans(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setWarning(err.message);
      console.error(err);
    }
  };

  /* ======================= ADD/EDIT SUBSCRIPTION ======================= */
  const manageSubscriptionHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        customerId: id,
        plan_name: document.getElementById("Plan").value,
        active: document.getElementById("Active").checked,
        amount: document.getElementsByName("amount")[0].value,
        description,
      };
      await requestAdminAPI("manage-subscription", payload);
      setEdit(false);
      fetchCustomer();
    } catch (err) {
      setWarning(err.message);
      setLoading(false);
    }
  };

  // Fetch Customer and Plans on Load
  useEffect(() => {
    if (id) fetchCustomer();
    fetchPlans();
  }, [id]);

  if (!id)
    return (
      <div className="container">
        <p className="text-stone-400">Customer ID Not Provided!</p>
      </div>
    );

  if (!loading && !customer)
    return (
      <div className="container">
        <p className="text-stone-400">Customer Not Found!</p>
      </div>
    );

  return (
    <div className="container">
      <Heading
        title="Manage Subscription"
        text="Edit the subscription plan for this customer."
      >
        {edit ? (
          <Fragment>
            <FiCheck onClick={manageSubscriptionHandler} className="icon" />
            <AiOutlineClose onClick={() => setEdit(false)} className="icon" />
          </Fragment>
        ) : (
          <Fragment>
            <MdRefresh onClick={fetchCustomer} className="text-2xl icon" />
            <FaEdit onClick={() => setEdit(true)} className="icon" />
          </Fragment>
        )}
      </Heading>
      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="grid gap-8">
          <div className="info">
            <DetailPair label="Customer ID" value={customer.id} />
            <DetailPair
              label="Customer"
              value={`${customer.firstName} ${customer.lastName}`}
            />
            <SelectPair
              label="Plan"
              value={customer.plan?.name}
              options={plans.map((plan) => plan.name)}
              edit={edit}
              onChange={onInputChange}
            />
            <div className="flex items-center space-x-4">
              <Switch
                label="Active"
                value={customer.plan?.active}
                edit={edit}
              />
            </div>
          </div>

          {edit && (
            <div className="text-sm info">
              <p className="text-xs text-stone-400 md:col-span-3">
                Customers can be charged for any changes to subscription plan.
                The amount is sent as an invoice. For no charges, simply leave
                the amount empty.
              </p>
              <div className="grid">
                <label className="text-stone-400">Charge Amount:</label>
                <input
                  className="form-input"
                  name="amount"
                  type="number"
                  onChange={onInputChange}
                />
              </div>
              <div className="grid md:col-span-2">
                <label className="text-stone-400">Description:</label>
                <input
                  className="form-input"
                  name="description"
                  placeholder="Description"
                  type="text"
                  value={description}
                  required
                  onChange={(e) => onInputChange(e, e.target.value)}
                />
              </div>
              {warning && <p className="md:col-span-3 warning">{warning}</p>}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
