export default function Radio({ options, selected, onClick }) {
  return options.map((option, index) => (
    <div
      className={`p-2 rounded-lg border border-stone-600 text-sm cursor-pointer font-semibold ${
        selected === option ? "bg-stone-600 text-white" : "text-stone-400"
      }`}
      onClick={() => onClick(option)}
      key={index}
    >
      <p className="text-center">{option}</p>
    </div>
  ));
}
