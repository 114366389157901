import { useContext } from "react";
import { UtilityContext } from "../../context/util-context";

export default function PoshLogo() {
  const { navigate } = useContext(UtilityContext);
  return (
    <div onClick={(e) => navigate("/", e)}>
      <h1 className="text-4xl font-bold text-yellow-600 font-Play">POSH</h1>
    </div>
  );
}
