import { useState, useContext, useEffect } from "react";
import DropDownSelector from "../UI/DropDownSelector";
import BarGraph from "../UI/BarGraph";
import { getVehicleStats } from "../../api/fetch";
import { AuthContext } from "../../context/auth-context";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import { arrayToCsv } from "../../util/arrayToCsv";
import { FiDownload } from "react-icons/fi";
import { requestAdminAPI } from "../../api/posh-api";

export default function RevenueGraph() {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [type, setType] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // ============================================= FETCH REVENUE FOR VEHICLES
  const fetchRevenueHandler = async () => {
    try {
      setLoading(true);
      const data = await requestAdminAPI("fetch-revenue", {
        year: selectedYear,
        type: type,
      });
      setData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // =================== DOWNLOAD CSV ===================
  const downloadCSV = () => {
    arrayToCsv(data, `${selectedYear}-revenue`);
  };

  useEffect(() => {
    fetchRevenueHandler();
  }, [selectedYear, type]);

  if (loading)
    return (
      <div className="h-[390px]">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div>
      <div className="flex items-center justify-end space-x-6">
        <select
          className="text-sm border-b rounded-none cursor-pointer input"
          defaultValue={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option value="">All</option>
          <option value="Booking">Bookings</option>
          <option value="Subscription">Subscription</option>
          <option value="Deposit">Deposits</option>
          <option value="Toll">Tolls</option>
        </select>

        <select
          className="text-sm border-b rounded-none cursor-pointer input "
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          {Array.from({ length: new Date().getFullYear() - 2022 + 1 }, (_, i) => {
            return <option value={2022 + i}>{2022 + i}</option>;
          })}
        </select>
        <FiDownload onClick={downloadCSV} className="text-2xl icon" />
      </div>
      <BarGraph data={data} expenses={false} />
    </div>
  );
}
