// External Imports
import { Fragment, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

// Internal Imports
import { requestAdminAPI } from "../../api/posh-api";
import Heading from "./Heading";
import LoadingSpinner from "./LoadingSpinner";
import NoteCard from "./NoteCard";

/** ========================================================================
 * * Notes Component
 * This component is used to display notes for a specific record and
 * allows the user to add new notes.
 * ========================================================================== */

export default function Notes({ onComplete, collection, id, notes = [] }) {
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);

  // =================== ADD NOTE ===================
  const addNoteHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const note = event.target.note.value;
      await requestAdminAPI("add-note", {
        collection,
        id,
        note,
      });
      onComplete && onComplete();
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="grid gap-4">
      <Heading
        title="Notes"
        text="Each record has individual notes. To add a note click the '+' icon on the right. Notes cannot be deleted once submitted."
        isBackArrow={false}
      >
        <AiOutlinePlus onClick={() => setAdd(true)} className="icon" />
      </Heading>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <div className="list max-h-[200px]">
            {notes.reverse().map((note, idx) => (
              <NoteCard key={idx} note={note} collection={collection} id={id} />
            ))}
          </div>
          {add && (
            <form
              onSubmit={addNoteHandler}
              className="grid items-end gap-4 md:grid-cols-5"
            >
              <input
                type="text"
                name="note"
                className="w-full form-input md:col-span-3"
                required
              />
              <button type="submit" className="btn-primary">
                Add Note
              </button>
              <div onClick={() => setAdd(false)} className="btn-secondary">
                Cancel
              </div>
            </form>
          )}
        </Fragment>
      )}
      {warning && <p className="warning">{warning}</p>}
    </div>
  );
}
