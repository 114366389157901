// External Packages
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Internal Components
import Heading from "../UI/Heading";
import SearchBar from "../UI/SearchBar";
import { MdRefresh } from "react-icons/md";
import { fetchLeads } from "../../api/fetch";
import LoadingSpinner from "../UI/LoadingSpinner";
import { AuthContext } from "../../context/auth-context";
import { UtilityContext } from "../../context/util-context";
import {
  timestampToDate,
  timestampToString,
} from "../../util/timestamp-to-date";
import { BiDownload } from "react-icons/bi";
import { arrayToCsv } from "../../util/arrayToCsv";

export default function Leads() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchParam = params.get("search") ?? "";

  // Load Context
  const { user } = useContext(AuthContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState("");
  const [leads, setLeads] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchValue, setSearchValue] = useState(searchParam);

  // ========================================================== FETCH LEADS
  const fetchLeadsHandler = async () => {
    try {
      setLoading(true);
      setWarning();
      const res = await fetchLeads(user, {});
      const sorted = res.sort(
        (a, b) =>
          timestampToDate(b.date_created) - timestampToDate(a.date_created)
      );
      setLeads(sorted);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      setWarning(err.message);
    }
  };
  useEffect(() => {
    fetchLeadsHandler();
  }, []);

  // Apply filters
  useEffect(() => {
    let results = [...leads];
    if (searchValue) {
      results = results.filter((lead) => {
        const string = `${lead.name} ${lead.phone} ${lead.notes} ${lead.status} 
      ${lead.mileage} ${lead.budget}`.toLowerCase();
        return string.includes(searchValue.toLowerCase());
      });
    }
    setFiltered([...(results ?? [])]);
  }, [searchValue, leads]);
  const downloadCSV = () => {
    arrayToCsv(leads, "Leads");
  };

  // Clear Search Filter
  const clearSearch = () => {
    setSearchValue("");
    navigate(`/leads?search=`, {}, { replace: true });
  };
  // Search handler
  const searchHandler = (value) => {
    navigate(`/leads?search=${value}`, {}, { replace: true });
    setSearchValue(value);
  };

  return (
    <div className="container grid">
      <Heading title="Leads">
        <MdRefresh className="icon" onClick={fetchLeadsHandler} />
        <BiDownload className="icon" onClick={downloadCSV} />
      </Heading>

      <SearchBar
        searchHandler={searchHandler}
        clearSearch={clearSearch}
        searchValue={searchValue}
      />

      <div className="list">
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="grid gap-8">
            {filtered.map((lead) => (
              <div
                onClick={(e) => navigate("/leads/info?id=" + lead.id, e)}
                key={lead.id}
                className="grid pill md:grid-cols-4"
              >
                <p>{timestampToString(lead.date_created, "MM/dd/yyyy")}</p>
                <p>{lead.name}</p>
                <p>{lead.phone}</p>
                <p>{lead.status || "-"}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex items-center justify-end space-x-4 text-sm text-stone-200">
        <p className="text-stone-400">
          Showing {filtered.length} of {leads.length}{" "}
        </p>
      </div>
      {warning && <p className="warning">{warning}</p>}
    </div>
  );
}
