// External Imports
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MdRefresh } from "react-icons/md";

// Internal Imports
import { timestampToString } from "../../util/timestamp-to-date";
import { fetchCoupon } from "../../api/fetch";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import { requestAdminAPI } from "../../api/posh-api";

/** ========================================================================
 * * Coupon Information Page
 * Renders a page, which displays details of a specific coupon.
 * ========================================================================== */

export default function CouponInfo() {
  // Get Vehicle ID from URL
  const [searchParms] = useSearchParams();
  const id = searchParms.get("id");

  // Initialize States
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(true);
  const [coupon, setCoupon] = useState(null);

  /* ======================= FETCH COUPON ======================= */
  const fetchCouponHandler = async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("fetch-coupon", { couponId: id });
      setCoupon(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // Fetch Coupon on Load
  useEffect(() => {
    if (id) fetchCouponHandler();
  }, [id]);

  if (!id)
    return (
      <div className="w-full h-full p-8 rounded-lg bg-stone-900">
        <p className="text-stone-400">Coupon ID Not Provided!</p>
      </div>
    );

  if (!loading && !coupon)
    return (
      <div className="w-full h-full p-8 rounded-lg bg-stone-900">
        <p className="text-stone-400">Coupon Not Found!</p>
      </div>
    );

  return (
    <div className="container grid">
      <Heading
        title="Coupon Details"
        text="Coupon cannot be deleted. However, you can disable it to prevent further use."
      >
        <MdRefresh onClick={fetchCouponHandler} className="text-2xl icon" />
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="grid gap-12">
          <div className="info">
            <DetailPair label="Coupon Code" value={coupon.id} />
            <DetailPair label="Type" value={coupon.type} />
            <DetailPair label="Amount" value={coupon.amount} />
            <DetailPair
              label="Reusable"
              value={coupon.reusable ? "Yes" : "No"}
            />
            <DetailPair
              label="For"
              value={coupon.forId ? coupon.customer : false}
              link={coupon.forId ? `/customers/info?id=${coupon.forId}` : false}
            />
          </div>

          <div>
            <Heading
              isBackArrow={false}
              title="Used By"
              text="The following customers have used the coupon to get the respective discounts"
            />
            <div className="list mt-2">
              {coupon.usedBy &&
                coupon.usedBy.map((u, idx) => (
                  <div
                    key={idx}
                    className="grid grid-cols-4 pill tex-sm text-stone-200"
                  >
                    <p>{timestampToString(u.usedOn, "MM/dd/yyyy")}</p>
                    <p>{u.id}</p>
                    <p>${u.subtotal}</p>
                    <p>-${u.discount}</p>
                  </div>
                ))}
            </div>
          </div>

          {warning && <p className="warning">{warning}</p>}
        </div>
      )}
    </div>
  );
}
