import { truncate } from "../../util/format-text";

export default function TollCard({ toll, selected, onClick }) {
  const clickHandler = () => {
    onClick && onClick(toll);
  };
  return (
    <div
      onClick={clickHandler}
      className={`grid items-center md:grid-cols-5 gap-x-6 text-stone-200 text-center ${
        selected ? "selected-card" : "card"
      }`}
    >
      <p>
        {toll.date}
      </p>
      <p className="md:col-span-2">{truncate(toll.location, 40)}</p>
      <p>
        {toll.agency}
      </p>
      <p className="font-semibold">${toll.amount}</p>
    </div>
  );
}
