// External Packages
import { useState, useEffect, useContext, useCallback } from "react";

// External Components
import { MdRefresh } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";

// Internal Components
import { UtilityContext } from "../../context/util-context";
import { requestAdminAPI } from "../../api/posh-api";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import LinkPair from "../UI/LinkPair";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import { timestampToString } from "../../util/timestamp-to-date";

export default function BookingStatus({ vehicle }) {
  // Load Context
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [booking, setBooking] = useState();
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(null);

  // =================== FETCH BOOKING ===================
  const fetchBookingHandler = useCallback(async () => {
    if (!vehicle?.bookings || vehicle?.bookings?.length < 1) return;
    try {
      setWarning();
      setLoading(true);
      const data = await requestAdminAPI("fetch-booking", {
        bookingId: vehicle.bookings[vehicle.bookings.length - 1].id,
      });
      setBooking(data);
      setLoading(false);
    } catch (err) {
      setWarning(err.message);
      setLoading(false);
    }
  }, [vehicle]);

  // =================== USE EFFECT ===================
  useEffect(() => {
    if (vehicle) fetchBookingHandler();
  }, [fetchBookingHandler, vehicle]);

  if (!booking) return null;

  return (
    <div className="grid gap-4 mb-4">
      <Heading
        title="Booking Status"
        text="To get more information, click external link icon on the right of group heading."
        isBackArrow={false}
      >
        <MdRefresh onClick={fetchBookingHandler} className="text-2xl icon" />
        <FiExternalLink
          onClick={(e) => navigate(`/bookings/info?id=${booking?.id}`, e)}
          className="icon"
        />
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="info">
          <DetailPair
            label="Booking ID"
            value={booking.id}
          />
          <DetailPair
            label="Customer"
            value={`${booking?.customer.firstName} ${booking?.customer.lastName}`}
            link={`/customer/info?id=${booking.customer.id}`}
          />
          <DetailPair
            label="Dates"
            value={`${timestampToString(
              booking.start_date,
              "MM/dd/yyyy"
            )} - ${timestampToString(booking.end_date, "MM/dd/yyyy")}`}
          />
        </div>
      )}
      {warning && <p className="text-sm text-red-600">{warning}</p>}
    </div>
  );
}
