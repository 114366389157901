// External Imports
import { Fragment, useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

// Internal Imports
import { AuthContext } from "../../context/auth-context";
import { requestAdminAPI } from "../../api/posh-api";
import { fetchCustomerById } from "../../api/fetch";
import { editCustomer } from "../../api/add";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import DetailPair from "../UI/DetailPair";
import Heading from "../UI/Heading";
import MessageModel from "./MessageModal";
import DropDownBanner from "../UI/DropDownBanner";
import LinkPair from "../UI/LinkPair";
import uploadFile from "../../util/upload-file";
import { UtilityContext } from "../../context/util-context";
import { truncate } from "../../util/format-text";
import { MdRefresh } from "react-icons/md";
import Notes from "../UI/Notes";

/** ========================================================================
 * * Customer Information Page
 * Renders a page, which displays all information about a customer.
 * ========================================================================== */

export default function CustomerInfo() {
  // Load Context
  const { user } = useContext(AuthContext);
  const { navigate } = useContext(UtilityContext);

  // Get Customer & Session ID from URL
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const sessionId = searchParams.get("sessionId");

  // Initialize States
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false);
  const [response, setResponse] = useState(true);
  const [edit, setEdit] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [phone, setPhone] = useState();

  /* =================== FETCH CUSTOMER ===================
  If sessionId is provided, setup ACH for customer
  else fetch customer information
  */
  const fetchCustomer = async () => {
    try {
      setLoading(true);
      try {
        if (sessionId)
          await requestAdminAPI("setup-ach", {
            customerId: id,
            sessionId: sessionId,
          });
      } catch (err) {
        setWarning(err.message);
      }
      const data = await fetchCustomerById(user, id);
      setCustomer(data);
      setPhone(data.phone);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setWarning(err.message);
      console.error(err);
    }
  };

  /* =================== EDIT Phone Number =================== */
  const handlePhoneChange = (e) => {
    setPhone(e.target.value); // update state on change
  };

  /* =================== EDIT CUSTOMER =================== */
  const editHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        customerId: id,
        phone: document.getElementById("Phone").value || "",
        dob: document.getElementById("DOB").value || "",
        address: document.getElementById("Address").value || "",
        drivers_license_number:
          document.getElementById("License Number").value || "",
        drivers_license_exp:
          document.getElementById("License Expiration").value || "",
      };

      const licensebackFile = document.getElementById("License Back").files[0];
      const licenseFrontFile =
        document.getElementById("License Front").files[0];

      if (licenseFrontFile) {
        const path = `users/${customer.id}/license_front.${licenseFrontFile.name
          .split(".")
          .pop()}`;
        payload.drivers_license_front = await uploadFile(
          path,
          licenseFrontFile
        );
      }

      if (licensebackFile) {
        const path = `users/${customer.id}/license_back.${licensebackFile.name
          .split(".")
          .pop()}`;
        payload.drivers_license_back = await uploadFile(path, licensebackFile);
      }
      await editCustomer(user, payload);
      setEdit(false);
      fetchCustomer();
    } catch (err) {
      console.error(err.message);
      setLoading(false);
      setWarning(err.message);
    }
  };

  /* =================== SEND ACH SETUP LINK =================== */
  const sendACHSetupLink = async () => {
    try {
      setLoading(true);

      // Get ACH setup link from API
      const hostedUrl = await requestAdminAPI("setup-ach", {
        customerId: customer.id,
      });

      // Send message to customer with ACH setup link
      const message = `Please click the following link to set up ACH: ${hostedUrl.url}`;
      await requestAdminAPI("send-message", { customerId: id, message });

      // Set response and loading state
      const response = `ACH Setup link sent to customer: ${hostedUrl.url}`;
      setResponse(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setWarning(err.message);
    }
  };

  /* =================== SEND SCREENING LINK =================== */
  const sendScreeningLinkHandler = async () => {
    try {
      setLoading(true);
      // Fetch screening link from API
      const hostedUrl = await requestAdminAPI("get-screening-link", {
        customerId: id,
      });

      // Send message to customer with screening link
      const message = `Please click the following link to upload your driver's license: ${hostedUrl.url}`;
      await requestAdminAPI("send-message", { customerId: id, message });

      // Set response and loading state
      const response = `Screening link: ${hostedUrl.url}`;
      setResponse(response);

      // Fetch customer information
      const data = await fetchCustomerById(user, id);
      setCustomer(data);

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setWarning(err.message);
    }
  };

  /* =================== CHECK SCREENING STATUS =================== */
  const checkScreeningStatusHandler = async () => {
    try {
      setLoading(true);
      await requestAdminAPI("check-screening-status", { customerId: id });
      fetchCustomer();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setWarning(err.message);
    }
  };

  /* =================== DO NOT RENT CUSTOMER =================== */
  const addToDNRHandler = async () => {
    try {
      setLoading(true);
      await requestAdminAPI("add-to-dnr", { customerId: id });
      fetchCustomer();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setWarning(err.message);
    }
  };

  // Render Screening Buttons based on screening status
  const renderScreeningMenu = () => {
    if (!customer.screening || customer?.screening.status !== "pending")
      return (
        <button onClick={sendScreeningLinkHandler} className="btn-secondary">
          Send Screening Link
        </button>
      );
    else if (customer.screening?.status !== "approved")
      return (
        <button onClick={checkScreeningStatusHandler} className="btn-secondary">
          Check Screening Status
        </button>
      );
  };

  // Fetch Customer Information on Page Load
  useEffect(() => {
    fetchCustomer();
  }, []);

  // Reset Warning and Response on Loading
  useEffect(() => {
    if (loading) {
      setWarning();
      setResponse();
    }
  }, [loading]);

  if (!id)
    return (
      <div className="container">
        <p className="text-stone-400">Customer ID Not Provided!</p>
      </div>
    );

  if (!loading && !customer)
    return (
      <div className="container">
        <p className="text-stone-400">Customer Not Found!</p>
      </div>
    );

  return (
    <div className="container">
      <MessageModel
        open={messageModal}
        setOpen={setMessageModal}
        customer={customer}
      />
      <Heading title="Customer Information">
        {edit ? (
          <Fragment>
            <AiOutlineClose onClick={() => setEdit(false)} className="icon" />
            <FiCheck onClick={editHandler} className="icon" />
          </Fragment>
        ) : (
          <Fragment>
            <FaEdit onClick={() => setEdit(true)} className="icon" />
            <MdRefresh onClick={fetchCustomer} className="icon" />
          </Fragment>
        )}
      </Heading>

      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="info">
          {customer.dnr && (
            <div className="absolute px-3 py-2 bg-red-600 rounded-md top-[15%] left-[55%] text-xs">
              Do Not Rent!
            </div>
          )}

          {customer?.deleted && (
            <div className="absolute px-3 py-2 bg-red-600 rounded-md top-[21%] left-[55%] text-xs">
              Customer Deleted!
            </div>
          )}

          <DetailPair label="Customer Id" value={customer.id} />
          <DetailPair
            label="Name"
            value={customer.firstName + " " + customer.lastName}
          />
          <DetailPair label="Email" value={customer.email} />
          <DetailPair
            label="Phone"
            value={phone}
            edit={edit}
            type="tel"
            placeholder={"e.g., +17812491152"}
            onChange={handlePhoneChange}
          />
          <DetailPair
            label="DOB"
            value={customer.dob}
            edit={edit}
            type={"date"}
            placeholder={"MM/DD/YYYY"}
          />
          <DetailPair
            label="License Number"
            value={customer.drivers_license_number}
            edit={edit}
          />
          <DetailPair
            label="License Expiration"
            value={customer.drivers_license_exp}
            edit={edit}
            type={"date"}
            placeholder={"MM/DD/YYYY"}
          />
          {edit && (
            <DetailPair label="Address" value={customer.address} edit={edit} />
          )}
          <DetailPair label="Street" value={customer.street} />
          <DetailPair label="City" value={customer.city} />
          <DetailPair label="State" value={customer.state} />
          <DetailPair label="Zip" value={customer.zip} />
          <DetailPair label="Country" value={customer.country} />
          <LinkPair
            label="License Front"
            link={customer.drivers_license_front}
            edit={edit}
            type="file"
          />
          <LinkPair
            label="License Back"
            link={customer.drivers_license_back}
            edit={edit}
            type="file"
          />
          <DropDownBanner
            data={[
              customer.default_payment_method,
              ...(customer.payment_methods || []),
            ]}
          >
            <DetailPair
              label="Default Payment"
              value={
                customer.default_payment_method
                  ? customer.default_payment_method.brand +
                    " " +
                    customer.default_payment_method.last4
                  : "-"
              }
            />
          </DropDownBanner>
          <DropDownBanner
            data={
              customer.documents
                ? customer.documents.map((doc) => ({ Link: doc }))
                : []
            }
          >
            <DetailPair label="Documents" value={customer.documents?.length} />
          </DropDownBanner>

          <LinkPair
            label="Screening Status"
            value={customer.screening?.status || "-"}
            link={
              customer.screening &&
              `${process.env.REACT_APP_PLAID_DASHBOARD_URL}/${
                process.env.REACT_APP_ENVIRONMENT === "LIVE" ? "" : "sandbox"
              }/identity_verification/sessions/${customer.screening.id}`
            }
          />
          {customer?.screening?.status === "pending" && (
            <LinkPair label="Screening Link" link={customer.screening.link} />
          )}

          <DetailPair
            label="Booking"
            value={
              customer.booking
                ? `${customer.booking.make} ${customer.booking.model} ${customer.booking.license}`
                : "-"
            }
            link={
              customer.booking && `/bookings/info?id=${customer.booking?.id}`
            }
          />
          <hr className="hr md:col-span-3" />
          {warning && (
            <p className="text-red-600 md:col-span-3">WARNING: {warning}</p>
          )}
          {response && (
            <p className="response md:col-span-3">
              RESPONSE: {truncate(response, 60)}
            </p>
          )}

          <div
            onClick={(e) =>
              navigate(`/payments/add?customerId=${customer.id}`, e)
            }
            className="btn-secondary"
          >
            Charge Customer
          </div>
          <div
            onClick={(e) =>
              navigate(`/bookings/add?customerId=${customer.id}`, e)
            }
            className="btn-secondary"
          >
            Add Booking
          </div>
          <div
            onClick={(e) =>
              navigate(`/customers/add-subscription?id=${customer.id}`, e)
            }
            className="btn-secondary"
          >
            {customer.plan?.active ? "Edit Subscription" : "Add Subscription"}
          </div>
          <div onClick={() => setMessageModal(true)} className="btn-secondary">
            Send Message
          </div>
          <div onClick={() => sendACHSetupLink()} className="btn-secondary">
            Send ACH Setup Link
          </div>
          {renderScreeningMenu()}
          {!customer.dnr && (
            <div onClick={addToDNRHandler} className="btn-warning">
              Add to DNR
            </div>
          )}
          <div className="md:col-span-3">
            <Notes
              collection="Customers"
              id={customer.id}
              notes={customer.notes}
              onComplete={fetchCustomer}
            />
          </div>
        </div>
      )}
    </div>
  );
}
