// External Imports
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

// Internal Imports
import { requestAdminAPI } from "../../api/posh-api";
import uploadFile from "../../util/upload-file";
import Heading from "../UI/Heading";
import ImageCarousel from "../UI/ImageCarousel";
import LoadingSpinner from "../UI/LoadingSpinner";
import { timestampToDate } from "../../util/timestamp-to-date";

/** ========================================================================
 * * CheckInForm Component
 * Renders a form, which allows changing a booking status to started.
 * ========================================================================== */

export default function CheckInForm({ setOpen, booking, onComplete }) {
  // Initialize States
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [item, setItem] = useState();

  /* ======================= START BOOKING ======================= */
  const startBookingHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      // Get Image URLs
      const imageUrls = [];
      /*
       * Upload images directly to the database
       * and get the URL of the uploaded images.
       */
      if (images.length > 0) {
        const path = `bookings/${booking.id}/vehicle_stats_image_end_`;
        await Promise.all(
          images.map(async (image, index) => {
            setItem(index + 1);
            const url = await await uploadFile(
              path + index.toLocaleString(),
              image,
              setUploading
            );
            imageUrls.push(url);
          })
        );
      }

      // Create Payload
      const payload = {
        bookingId: booking.id,
        check_out_images: imageUrls,
      };

      // Send Request
      await requestAdminAPI("complete-booking", payload);
      onComplete();
    } catch (err) {
      setLoading(false);
      setUploading(false);
      setWarning(err.message);
    }
  };

  return (
    <div className="container">
      <Heading
        title="Check Out"
        text="End Mileage and Fuel is automatically fetched from trackers."
      >
        <AiOutlineClose onClick={() => setOpen(false)} className="icon" />
      </Heading>

      <form onSubmit={startBookingHandler} className="grid gap-4">
        <p className="text-sm text-stone-400">Images:</p>
        <ImageCarousel images={images} setImages={setImages} preview={true} />

        {loading ? (
          <LoadingSpinner />
        ) : (
          <button className="btn-primary w-fit" type="submit">
            Submit
          </button>
        )}
        {warning && <p className="text-sm text-red-600">{warning}</p>}
        {uploading && (
          <p className="text-sm text-stone-400">
            Uploading Image {item}... {uploading}%
          </p>
        )}
      </form>
    </div>
  );
}
