import { Fragment, useContext, useEffect, useState } from "react";
import Heading from "../UI/Heading";
import { BsPlusLg } from "react-icons/bs";
import DetailPair from "../UI/DetailPair";
import { AiOutlineClose } from "react-icons/ai";
import { addPartnerPayment, deletePartnerPayment } from "../../api/add";
import { AuthContext } from "../../context/auth-context";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import { fetchPartnerById } from "../../api/fetch";
import uploadFile from "../../util/upload-file";
import { timestampToString } from "../../util/timestamp-to-date";
import { FaTrashAlt } from "react-icons/fa";

export default function PartnerPayments({ partnerId, setOpen }) {
  const { user } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState();
  const [partner, setPartner] = useState();

  const paymentCard = (payment) => (
    <div key={payment.id} className="flex items-center space-x-4">
      <div className="grid w-full text-sm md:grid-cols-5 pill">
        <p>
          {timestampToString(
            payment.date_paid || payment.date_created,
            "MM/dd/yyy"
          )}
        </p>
        <p className="md:col-span-2">{payment.description}</p>
        {payment.attachment ? (
          <a
            href={payment.attachment}
            target="_blank"
            className="ml-auto underline text-primary"
          >
            Attachment
          </a>
        ) : (
          <p className="ml-auto">-</p>
        )}
        <p className="ml-auto">${Number(payment.amount).toFixed(2)}</p>
      </div>

      <FaTrashAlt
        title="delete"
        className="text-lg cursor-pointer text-stone-400 hover:text-stone-400"
        onClick={() => deletePaymentHandler(payment.id)}
      />
    </div>
  );

  // ========================================= FETCH PARTNER
  const fetchPartnerHandler = async () => {
    try {
      setLoading(true);
      const response = await fetchPartnerById(user, partnerId);
      setPartner(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // ========================================= ADD PAYMENT
  const addPaymentHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setWarning();
      const payload = {
        id: `${Date.now()}`,
        amount: document.getElementById("Amount").value,
        description: document.getElementById("Description").value,
        date_paid: document.getElementById("Date Paid").value,
        paid_via: document.getElementById("Paid Via").value,
        partnerId: partner.id,
      };
      const attachment = document.getElementById("Attachment").files[0];
      if (attachment) {
        const path = `partners/${user.uid}/payments/${
          payload.id
        }/attachment.${attachment.name.split(".").pop()}`;
        payload.attachment = await uploadFile(path, attachment, () => {});
      }
      const response = await addPartnerPayment(user, payload);
      fetchPartnerHandler();
      setAdd(false);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  // ========================================= DELETE PAYMENT
  const deletePaymentHandler = async (paymentId) => {
    try {
      setLoading(true);
      const response = await deletePartnerPayment(user, { id: paymentId });
      fetchPartnerHandler();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPartnerHandler();
  }, []);

  return (
    <div className="container">
      <Heading
        title="Partner Payments"
        text="This is a list of payments made to the partners and invoices received from the partner."
        isBackArrow={false}
      >
        {add ? (
          <AiOutlineClose
            onClick={() => setAdd(false)}
            className="text-xl text-white cursor-pointer hover:text-stone-400"
          />
        ) : (
          <Fragment>
            <AiOutlineClose
              onClick={() => setOpen(false)}
              className="text-xl text-white cursor-pointer hover:text-stone-400"
            />
            <BsPlusLg
              onClick={() => setAdd(true)}
              className="text-white cursor-pointer hover:text-stone-400"
            />
          </Fragment>
        )}
      </Heading>
      {loading ? (
        <LoadingSpinnerWrapper />
      ) : add ? (
        <form onSubmit={addPaymentHandler} className="info">
          <DetailPair
            label="Amount"
            edit={true}
            type="number"
            required={true}
          />
          <div className="md:col-span-2">
            <DetailPair label="Description" edit={true} required={true} />
          </div>
          <DetailPair
            label="Date Paid"
            edit={true}
            type="date"
            required={true}
          />
          <DetailPair label="Paid Via" edit={true} required={true} />
          <DetailPair label="Attachment" edit={true} type="file" />
          <button type="submit" className="btn-primary">
            Add Payment
          </button>
          {warning && <p className="text-red-600">{warning}</p>}
        </form>
      ) : (
        <div className="grid gap-8">
          <div className="list">
            {partner.payments.map((payment) => paymentCard(payment))}
          </div>
          <div className="ml-auto text-sm text-stone-400">
            <p>
              Showing {partner.payments.length} - Total $
              {partner.payments
                .reduce((acc, payment) => acc + parseFloat(payment.amount), 0)
                .toFixed(2)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
