import { useContext } from "react";
import { UtilityContext } from "../../context/util-context";
import { timestampToString } from "../../util/timestamp-to-date";
import truncate from "../../util/truncate";

export default function ExpenseCard({ expense }) {
  const { navigate } = useContext(UtilityContext);
  return (
    <div onClick={(e) => navigate(`/expenses/info?id=${expense.id}`, e)} className="grid text-sm md:grid-cols-6 text-stone-200 pill">
      <p className="md:col-span-2">{`${expense.vehicle?.make} ${expense.vehicle?.model} ${expense.vehicle?.year} ${expense.vehicle?.license}`}</p>
      <p className="md:col-span-2">{truncate(expense.description, 30)}</p>
      <p>{timestampToString(expense.date, "eee, do MMM")}</p>
      <p>${expense.amount}</p>
    </div>
  );
}
