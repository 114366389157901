// Function that takes an array of objects and returns a download link to a csv file

export const arrayToCsv = (array, filename) => {
    if (array.length === 0) {
        console.log("no data");
        return;
    }
    const csvRows = [];
    const headers = Object.keys(array[0]);
    csvRows.push(headers.join(','));
    for (const row of array) {
        const values = headers.map(header => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    }
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.click();
}