import { useContext,Fragment } from "react";
import { UtilityContext } from "../../context/util-context";
import { ACCEPTABLE_IMAGE_FORMATS } from "../../util/constants";
import InputMask from 'react-input-mask'; 

export default function DetailPair({
  label,
  value,
  link,
  edit,
  id,
  required,
  placeholder,
  type,
  disabled,
  onChange,
}) {
  const { navigate } = useContext(UtilityContext);

  const clickHandler = (e) => {
    if (link) navigate(link, e);
  };

  const classNames = () => { 
    let classes = "";
    if (link) classes += "cursor-pointer hover:opacity-70 text-yellow-600";
    return classes;
  };

  return (
    <div onClick={clickHandler} className={classNames()}>
      <p className="text-stone-400">{label}:</p>
      {!edit && <p className="text-stone-200">{value || "-"}</p>}
      {edit && (
        <Fragment>
          {type === 'tel' ? (
            // Apply mask for phone number input
            <InputMask
              mask="+9 999 999 9999"
              value={value}  // controlled value
              onChange={onChange}  // handle change via passed function
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  className={`w-full ${
                    disabled ? "form-input-disabled" : "form-input"
                  }`}
                  id={id || label}
                  placeholder={placeholder || ""}
                  name={id || label}
                  type={type || "text"}
                  required={required}
                  disabled={disabled}
                />
              )}
            </InputMask>
          ) : (
            <input
              className={`w-full ${
                disabled ? "form-input-disabled" : "form-input"
              }`}
              defaultValue={value}
                            id={id || label}
              placeholder={placeholder || ""}
              name={id || label}
              type={type || "text"}
              required={required}
              disabled={disabled}
              onChange={onChange && onChange}   
              accept={type === "file" ? ACCEPTABLE_IMAGE_FORMATS : undefined}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}
