// External Packages
import { useState, Fragment } from "react";

// Internal Packages
import { BsFilter } from "react-icons/bs";

export default function FilterMenu({ onSubmit, options }) {
  const [show, setShow] = useState(false);

  const submitHandler = (event) => {
    event.preventDefault();
    let response = {};
    options.forEach((option) => {
      response[option.label.toLowerCase()] = event.target[option.label].value;
    });
    onSubmit && onSubmit(response);
    setShow(false);
  };

  return (
    <Fragment>
      {show && (
        <div
          onClick={() => setShow(false)}
          className="absolute top-0 left-0 w-screen h-screen cursor-pointer bg-stone-900 z-1 opacity-20"
        ></div>
      )}
      <div className="relative cursor-pointer">
        <BsFilter
          onClick={() => setShow(!show)}
          className="text-2xl text-white hover:text-stone-400"
        />
        {show && (
          <form
            onSubmit={submitHandler}
            className="absolute top-0 z-10 grid gap-4 p-4 text-xs text-left rounded-lg w-36 text-stone-200 right-8 bg-stone-700"
          >
            {options?.map((option, idx) => (
              <div key={idx} className="grid gap-1">
                <p className="font-semibold">{option.label}</p>
                <select className="form-input" name={option.label}>
                  {option.options.map((opt, idx) => (
                    <option
                      key={idx}
                      value={opt}
                      selected={option.selected === opt}
                    >
                      {opt}
                    </option>
                  ))}
                </select>
              </div>
            ))}

            <button type="submit" className="btn-primary">
              Apply
            </button>
          </form>
        )}
      </div>
    </Fragment>
  );
}
