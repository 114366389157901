import { useState } from "react";
import { MdAddAPhoto } from "react-icons/md";
import LoadingSpinner from "./LoadingSpinner";

export default function ImageCarousel({ images, setImages, preview }) {
  const [displayImage, setDisplayImage] = useState(false);
  const [carousel, setCarousel] = useState([]);
  const [loading, setLoading] = useState(false);

  const addImage = async () => {
    try {
      const fileInput = document.getElementById("image-input");
      fileInput.click();
      setLoading(true);
      await fileInput.addEventListener("change", (e) => {
        const files = e.target.files;
        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setImages([...images, file]);
          setCarousel([...carousel, reader.result]);
          setDisplayImage(reader.result);
        };
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const removeImage = () => {
    if (images.length > 1) {
      setImages(images.slice(0, -1));
      setDisplayImage(images[images.length - 2]);
    } else if (images.length === 1) {
      setImages([]);
      setDisplayImage(false);
    }
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div className="grid gap-4">
      {preview && (
        <div>
          {displayImage ? (
            <img
              src={displayImage}
              className="w-full h-full rounded-xl md:max-w-[500px]"
            />
          ) : (
            <div className="md:w-[500px] w-full h-[200px] bg-gray-300 dark:bg-stone-700 rounded-xl"></div>
          )}
        </div>
      )}
      <div className="flex py-4 space-x-2 overflow-scroll no-scrollbar">
        {carousel.map((image, idx) => (
          <img
            key={idx}
            onClick={() => setDisplayImage(image)}
            className="w-16 h-16 rounded-xl"
            src={image}
          />
        ))}
        <div
          onClick={addImage}
          className="flex items-center justify-center w-16 h-16 bg-gray-300 cursor-pointer dark:bg-stone-700 rounded-xl hover:bg-stone-400 hover:dark:bg-stone-800"
        >
          <MdAddAPhoto className="w-8 h-8 mx-4 text-white" />
        </div>
      </div>
      <input className="hidden" id="image-input" type="file" accept="image/*" />
      {images.length > 0 && (
        <div onClick={removeImage} className="btn-secondary">
          Remove Image
        </div>
      )}
    </div>
  );
}
